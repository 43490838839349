import { HistoryHeader } from 'components'
import intl from 'localization/components'
import { useIntl } from 'react-intl'
import FraudIndicatorsTabs from './widgets/FraudIndicatorsTabs'
import FraudIndicatorsContent from './widgets/FraudIndicatorsContent'
import FraudIndicatorsProvider from './FraudIndicatorsProvider'
import FraudIndicatorsSaveModal from './widgets/Modals/FraudIndicatorsSaveModal'
import BottomBar from './widgets/BottomBar'
import ActivateFraudIndicatorsModal from './widgets/Modals/ActivateFraudIndicatorsModal'
import { useAccountStatus } from 'services/queries'
import FraudIndicatorsSubscribe from './FraudIndicatorsSubscribe'

const FraudIndicatorsPage = () => {
  const { messages } = useIntl()
  const { data: accountStatusData, isFetching: isAccountStatusFetching } =
    useAccountStatus()
  const {
    rights: { global },
  } = accountStatusData ?? { rights: { global: { fraud_indicators: false } } }
  const hasAccessToFraudIndicators = global?.fraud_indicators

  if (!isAccountStatusFetching && !hasAccessToFraudIndicators) {
    return <FraudIndicatorsSubscribe />
  }

  return (
    <FraudIndicatorsProvider>
      <HistoryHeader
        title={messages[intl.navigation('fraud-indicators')] as string}
        location="fraud-indicators"
        historyKey="fraud-indicators"
      />
      <FraudIndicatorsTabs />
      <FraudIndicatorsContent />
      <BottomBar />
      <FraudIndicatorsSaveModal />
      <ActivateFraudIndicatorsModal />
    </FraudIndicatorsProvider>
  )
}

export default FraudIndicatorsPage
