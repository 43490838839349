import React from 'react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import {
  iconContainer,
  promotionContainer,
  promotionText,
} from './FraudIndicatorsPromotion.styles'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

type FraudIndicatorsPromotionProps = {
  onClick: () => void
}

export const FraudIndicatorsPromotion = (props: FraudIndicatorsPromotionProps) => {
  const { onClick } = props
  return (
    <Box sx={promotionContainer} onClick={onClick} data-cy="fraud-indicators-promotion">
      <Box sx={iconContainer}>
        <img
          alt="risika blue logo shield"
          src={'/risika/logoshield-blue.svg'}
          height={32}
          width={32}
        />
      </Box>
      <Box sx={promotionText}>
        <Typography variant="body1">
          <FormattedMessage id={intl.creditPolicyNew('fraud-indicators-promotion')} />
        </Typography>
      </Box>
    </Box>
  )
}
