import React from 'react'

import { ShowHideAllWrapper } from 'components'
import SimpleEntry from './SimpleEntry'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

const Ean = ({ data }) => {
  const [isShowAll, setIsShowAll] = React.useState(false)
  return (
    <div style={{ marginBottom: data.length > 1 ? '1rem' : '' }}>
      <SimpleEntry
        title={<FormattedMessage id={intl.companyInfo('ean')} />}
        elementType="div"
        content={[
          data?.map((entry, i) => (
            <ShowHideAllWrapper
              setIsShowAll={setIsShowAll}
              isShowAll={isShowAll}
              key={i}
              amountOfShowedItems={3}
              iteration={i}
              dataLength={data?.length}
            >
              <div style={{ display: 'flex' }}>{entry.name}</div>
            </ShowHideAllWrapper>
          )),
        ]}
      />
    </div>
  )
}

export default Ean
