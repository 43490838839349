import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { navigation } from 'services/navigation'
import { Box, Typography } from '@mui/material'
import {
  Company,
  RisikaScore,
  TableCell,
  PaginatedIntlReactTable,
  Stack,
} from 'components'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { safely } from 'utils/safely'
import { clearHistory } from 'store_deprecated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'

const safeLowerCase = safely((str) => str.toLowerCase())

const blacklistedCompanyType = (companyType) =>
  ['enk'].includes(safeLowerCase(companyType))

function SortIndicator({ direction }) {
  if (direction === 'asc') {
    return <FontAwesomeIcon icon={faArrowDown} data-cy="sort-indicator" />
  } else if (direction === 'desc') {
    return <FontAwesomeIcon icon={faArrowUp} data-cy="sort-indicator" />
  } else {
    return (
      <FontAwesomeIcon
        icon={faArrowDown}
        style={{ opacity: 0.3 }}
        data-cy="sort-indicator"
      />
    )
  }
}

function sortFn(sortProp, callback) {
  callback((sort) => {
    if (sort?.[sortProp] == null) {
      return {
        [sortProp]: 'asc',
      }
    } else if (sort?.[sortProp] === 'asc') {
      return {
        [sortProp]: 'desc',
      }
    } else {
      return null
    }
  })
}

export const CompanyTable = (props) => {
  const {
    data,
    history,
    sortBy,
    setSort,
    companyCount,
    boxRef,
    loadNextResults,
    pagination,
  } = props
  const dispatch = useDispatch()
  const showStatus = (active) =>
    active ? (
      <FormattedMessage id={intl.generic('active')} />
    ) : (
      <FormattedMessage id={intl.generic('inactive')} />
    )

  const showLocalID = (localID) => `${localID.id} (${localID.country})`

  const showAddress = (address) => {
    const { city, municipality } = address

    if (city) {
      return city.includes(municipality) || !municipality
        ? city
        : `${city} (${municipality})`
    }
  }

  const rowData = data?.map((row) => ({
    ...row,
    display_company: (
      <Company
        dataCy={`company-name-${row.local_organization_id.id}`}
        isActive
        primary={row.company_name}
        secondary={
          row.address ? (
            showAddress(row.address)
          ) : (
            <FormattedMessage id={intl.generic('missing-address')} />
          )
        }
      />
    ),
    display_score: (
      <RisikaScore
        dataCy={`risika-score-${row.local_organization_id.id}`}
        value={row.score}
        localID={row.local_organization_id}
        displayNumericScore={!blacklistedCompanyType(row.company_type)}
      />
    ),
    display_local_id: (
      <TableCell dataCy={`local-id-${row.local_organization_id.id}`}>
        {showLocalID(row.local_organization_id)}
      </TableCell>
    ),
    display_type: (
      <TableCell dataCy={`display–type-${row.local_organization_id.id}`}>
        {row.company_type}
      </TableCell>
    ),
    display_status: (
      <TableCell dataCy={`status-${row.local_organization_id.id}`}>
        {showStatus(row.active)}
      </TableCell>
    ),
    display_employees: (
      <TableCell dataCy={`number-of-employees-${row.local_organization_id.id}`}>
        {row.employees_interval === '1000-999999' ? '1000+' : row.employees_interval}
      </TableCell>
    ),
  }))

  const handleClickOnRow = (state, rowInfo) => ({
    style: { cursor: 'pointer' },
    onClick: () => {
      if (!rowInfo) {
        return
      }
      dispatch(clearHistory('advancedfilters'))
      const { id } = rowInfo.original.local_organization_id
      history.push(
        navigation.creditCheck({
          ...rowInfo.original.local_organization_id,
        }),
        { id }
      )
    },
  })

  return (
    <PaginatedIntlReactTable
      pagination={pagination}
      boxRef={boxRef}
      companyCount={companyCount}
      loadNextResults={loadNextResults}
      data={rowData}
      manual
      filterable={false}
      sortable={false}
      className={'-striped -highlight border-0'}
      getTdProps={handleClickOnRow}
      columns={[
        {
          Header: (
            <Stack style={{ display: 'contents' }}>
              <Typography variant="subtitle2">
                <FormattedMessage id={intl.searchBusiness('company')} />
              </Typography>
              <Box
                ml={2}
                onClick={() => sortFn('company_name', setSort)}
                style={{ cursor: 'pointer' }}
              >
                <SortIndicator direction={sortBy?.company_name} />
              </Box>
            </Stack>
          ),
          accessor: 'display_company',
          width: 360,
        },
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.searchBusiness('risika-score')} />
            </Typography>
          ),
          accessor: 'display_score',
          className: 'font-bold justify-center',
          width: 140,
        },
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.searchBusiness('local-id')} />
            </Typography>
          ),
          accessor: 'display_local_id',
          className: 'justify-center',
        },
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.searchBusiness('status')} />
            </Typography>
          ),
          accessor: 'display_status',
          className: 'justify-center',
        },
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.searchBusiness('type')} />
            </Typography>
          ),
          accessor: 'display_type',
          className: 'justify-center',
        },
        {
          Header: (
            <Typography variant="subtitle2">
              <FormattedMessage id={intl.searchBusiness('employees')} />
            </Typography>
          ),
          accessor: 'display_employees',
          className: 'justify-center',
        },
      ]}
    />
  )
}

export default withRouter(CompanyTable)
