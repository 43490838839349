import React from 'react'

import { ShowHideAllWrapper } from 'components'
import SimpleEntry from './SimpleEntry'

import { navigation } from 'services/navigation'

import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ButtonLink2 } from 'components-new'

const Auditors = ({ data, handleClick }) => {
  const [isShowAll, setIsShowAll] = React.useState(false)

  return (
    <div style={{ marginBottom: data.length > 1 ? '1rem' : '' }}>
      <SimpleEntry
        title={<FormattedMessage id={intl.companyInfo('auditors')} />}
        elementType="div"
        content={[
          data?.map((entry, i) => (
            <ShowHideAllWrapper
              setIsShowAll={setIsShowAll}
              isShowAll={isShowAll}
              key={i}
              amountOfShowedItems={3}
              iteration={i}
              dataLength={data?.length}
            >
              <ButtonLink2
                role="link"
                onClick={(e) =>
                  handleClick(e, navigation.creditCheck(entry?.local_organization_id), {
                    id: entry?.local_organization_id?.id,
                  })
                }
                style={{ display: 'flex' }}
              >
                {entry.name}
              </ButtonLink2>
            </ShowHideAllWrapper>
          )),
        ]}
      />
    </div>
  )
}

export default Auditors
