import * as React from 'react'
import { FormGroup, Divider, Box } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { classes } from '../../styles/selected-fields-content'
import { generalLabelKeys, financialLabelKeys } from './labelKeyModel'
import Section from './Section'
import { Option } from 'components'

/**
 *
 * To add a section please follow these comments. I promise it wont take long :D
 *
 *  1. Go to line 33 - 112 and create a new array with the keys you will use. One in Danish and one in English
 *  2. Now follow the comments in the file. Add value to default state line 203
 */

const selectBoolsTrue = {
  all: true,
  general: true,
  financial: true,
  // Next create a local variable line 225
}

const defaultSelectBools = {
  all: false,
  general: false,
  financial: false,
  // Dont forget to add one with true to the one above :D
}

const SelectFieldsContent = ({
  setSelectedFields,
  selectedFields,
  shouldRenderFraudIndicators = false,
}) => {
  // selectBools --> group checkboxes on the top of the sections
  const [selectBools, setSelectBools] = React.useState(defaultSelectBools)
  const reactIntl = useIntl()
  const messages = reactIntl.messages

  const getLabelsFromKeys = (keysArr, category = 'generic') => {
    const translatedLabels = []
    keysArr.forEach((labelKey) => {
      const addTranslation =
        labelKey !== 'fraud-indicators' || shouldRenderFraudIndicators
      if (addTranslation) {
        translatedLabels.push(messages[intl[category](labelKey)])
      }
    })
    return translatedLabels
  }

  const generalData = getLabelsFromKeys(generalLabelKeys)
  const financialData = getLabelsFromKeys(financialLabelKeys, 'keyFinancialFigures')

  // Create a local variable that will depend on the user language. Just use the function provided above.
  // Next line 233

  const handleSelectAll = () => {
    if (!selectBools.all) {
      setSelectBools(selectBoolsTrue)
      setSelectedFields([...generalData, ...financialData])
      // Add the new section to the select all function :)
      // Next create a section element in the render method. Line 302
    } else {
      setSelectedFields([])
      setSelectBools(defaultSelectBools)
    }
  }

  const handleSelectSection = (sectionSelected, sectionData, selection) => {
    if (!sectionSelected) {
      setSelectBools({
        ...selectBools,
        [selection]: true,
      })
      setSelectedFields([...new Set([...selectedFields, ...sectionData])])
    } else {
      setSelectedFields(selectedFields.filter((x) => !sectionData.includes(x)))
      setSelectBools({
        ...selectBools,
        [selection]: false,
      })
    }
  }

  const handleChecked = (element) => {
    if (selectedFields.includes(element)) {
      setSelectedFields(selectedFields.filter((x) => x !== element))
    } else {
      setSelectedFields([...selectedFields, element])
    }
  }

  return (
    <FormGroup sx={classes.main}>
      <Option
        sx={classes.label}
        checked={selectBools.all}
        onChange={handleSelectAll}
        label={<FormattedMessage id={intl.advancedSearch('select-all')} />}
      />
      <Divider />
      <Box sx={classes.container}>
        <Section
          title={<FormattedMessage id={intl.advancedSearch('general-data-section')} />}
          selectAllLabel={
            <FormattedMessage id={intl.advancedSearch('general-data-select-all')} />
          }
          data={generalData}
          checkAllString="all-general"
          handleChecked={handleChecked}
          handleSelectSection={() =>
            handleSelectSection(selectBools.general, generalData, 'general')
          }
          selectedFields={selectedFields}
          groupSelected={selectBools.general}
        />
        <Section
          title={<FormattedMessage id={intl.advancedSearch('financial-data-section')} />}
          selectAllLabel={
            <FormattedMessage id={intl.advancedSearch('financial-data-select-all')} />
          }
          data={financialData}
          handleChecked={handleChecked}
          handleSelectSection={() =>
            handleSelectSection(selectBools.financial, financialData, 'financial')
          }
          selectedFields={selectedFields}
          groupSelected={selectBools.financial}
        />
      </Box>
    </FormGroup>
  )
}

export default SelectFieldsContent
