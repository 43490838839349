import { useState, Fragment } from 'react'

import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'

import { navigation } from 'services/navigation'

import { FormattedMessage, injectIntl } from 'react-intl'
import intl from 'localization/components'

const useStyles = makeStyles(() => ({
  sub_title: {
    fontWeight: 700,
    '&:first-child': {
      marginTop: 0,
    },
  },
  founders_auditors: {
    '&:hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
  },
  showAllButton: {
    marginTop: '0.75rem',
    paddingTop: '0.75rem',
    borderTop: '1px solid #979797',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

/**
 * Fields with * are required !
 *
 * header* requires data in this format "intl.companyRoles('board-of-directors')"
 * data* is always [{*name:'...',label:''}]
 * handleClick* returns event and value
 * isLabel in case you need gray label (shares, position)
 * message is shown in case data is empty
 * notLink avoids errors in case the entry does not handle onClick event
 *
 */

const ListRole = (props) => {
  const classes = useStyles()
  const [showAll, setShowAll] = useState(false)
  const {
    header,
    data,
    handleClick,
    intl: { messages },
    isLabel,
    message,
    notLink,
    role,
  } = props
  if (data.length <= 0) {
    return (
      <Fragment>
        <Typography variant="subtitle1">
          <FormattedMessage id={header} />
        </Typography>
        <Typography variant="body2">{message}</Typography>
      </Fragment>
    )
  }

  const getLabel = (functions) => {
    const temp = functions.filter((x) => {
      return role.includes(x.function)
    })
    return temp?.[0]?.title
  }
  return (
    <Fragment>
      <Typography variant="subtitle1">
        <FormattedMessage id={header} />
      </Typography>
      {data.map(
        (
          {
            name,
            label,
            shares,
            type,
            personal_id,
            local_organization_id,
            functions,
            country,
          },
          i
        ) => {
          let to = ''
          if (!notLink) {
            to = navigation.relationOverview({ country, id: personal_id })
            if (type && type.toUpperCase() === 'COMPANY') {
              to = {
                pathname: navigation.creditCheck(local_organization_id),
                state: { id: local_organization_id.id },
              }
              if (!local_organization_id.id) {
                to = ''
              }
            }
          }
          if (isLabel) {
            if (label === 'real_owner' || label === 'legal_owner') {
              label = shares || '-'
            } else {
              label = getLabel(functions) || messages[intl.relations(label)]
            }
          }
          return (
            <div key={name}>
              <Typography
                style={!showAll && i >= 3 ? { display: 'none' } : { display: '' }}
                className={!notLink && to ? classes.founders_auditors : ''}
                id={data[i].personal_id}
                onClick={(e) => to && handleClick(e, to)}
                key={i}
                variant="body2"
              >
                {name} {isLabel && <span className="gray">{`(${label})`}</span>}
              </Typography>

              {i === 3 && !showAll ? (
                <button
                  key={name + 'key Salt'}
                  className={classes.showAllButton}
                  onClick={() => setShowAll(true)}
                  type="button"
                >
                  <FormattedMessage id={intl.companyRoles('show-all')} />{' '}
                  {`(${data.length - 3})`}
                </button>
              ) : (
                i === data.length - 1 &&
                showAll && (
                  <button
                    key={name}
                    className={classes.showAllButton}
                    onClick={() => setShowAll(false)}
                    type="button"
                  >
                    <FormattedMessage id={intl.companyRoles('show-less')} />
                  </button>
                )
              )}
            </div>
          )
        }
      )}
    </Fragment>
  )
}

export default injectIntl(ListRole)
