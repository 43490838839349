import { FraudIndicatorDataPointType } from 'components-new/data-display/Table/elements/Changes/Change.types'
import risikaAPI from 'services/api/helpers/risikaAPI'
import { SupportedCountry } from 'types/general'

export type FraudIndicatorPolicyResponse = {
  country: string
  enabled: boolean
  policy: Record<FraudIndicatorDataPointType, boolean>
}

type ResponseData = {
  data: FraudIndicatorPolicyResponse
}

async function getFraudIndicatorsPolicy({
  country,
}: {
  country: SupportedCountry
}): Promise<FraudIndicatorPolicyResponse> {
  const resp: ResponseData = await risikaAPI.get(`/fraud_policy/get?country=${country}`, {
    microservice: 'data',
  })
  return resp.data
}

export default getFraudIndicatorsPolicy
