import React from 'react'
import { Typography, Tab, Tabs } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedList } from 'store_deprecated'
import { useMonitorShowList } from 'services/queries'
import { Mixpanel } from 'services/helpers/mixpanel'

const HorizontalTabList = () => {
  const dispatch = useDispatch()
  const selectedList = useSelector(
    (state) => state?.risika?.newRiskMonitoring?.selectedList
  )
  const { data: showListsData } = useMonitorShowList()

  if (selectedList !== 0 && !selectedList) {
    dispatch(setSelectedList(showListsData[0]?.list_id))
  }

  return (
    <Tabs
      variant="scrollable"
      value={selectedList}
      onChange={(e, newValue) => {
        Mixpanel.track('User chose monitoring list', {
          listId: newValue,
        })
        dispatch(setSelectedList(parseInt(newValue)))
      }}
      indicatorColor="primary"
      textColor="primary"
    >
      {showListsData?.map((list) => (
        <Tab
          value={list?.list_id}
          key={list?.list_id}
          label={<Typography variant="subtitle2">{list?.name?.toUpperCase()}</Typography>}
          data-cy={
            list?.list_id === selectedList ? 'selected-list-tab' : 'unselected-list-tab'
          }
        />
      ))}
    </Tabs>
  )
}

export default HorizontalTabList
