import { theme } from 'styles-new/mui5-transition/theme'

const lowerContainer = {
  height: '600px',
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  position: 'relative',
}

const parentContainer = {
  display: 'flex',
  flexDirection: 'column',
  height: '730px',
  position: 'relative',
  width: '350px',
  justifyContent: 'center',
  backgroundColor: theme.palette.common.white,
  borderRadius: '4px 4px 0px 0px',
}

const topContainer = {
  height: '200px',
  paddingTop: '18px',
  paddingLeft: theme.spacing(5),
  backgroundColor: theme.palette.primary.main,
  borderRadius: '4px 4px 0px 0px',
  lineHeight: '3',
}

const contact = {
  position: 'absolute',
  top: '32.5%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '300px',
  zIndex: 2,
}

const chatWithUs = {
  width: '300px',
  height: theme.spacing(8),
  borderRadius: theme.spacing(1),
  background: theme.palette.primary.light,
  marginTop: '171px',
  padding: theme.spacing(4),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  boxShadow:
    '0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 2px 2px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.08)',
}

const quickAnswers = {
  display: 'flex',
  justifyContent: 'left',
  flexDirection: 'column',
  width: '300px',
  marginTop: theme.spacing(4),
  padding: theme.spacing(4),
  borderRadius: '4px 4px 0px 0px',
  boxShadow:
    '0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 2px 2px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.08)',
}

const clickableOption = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '42px',
  cursor: 'pointer',
  ':hover': {
    textDecoration: 'underline',
  },
}

const optionContainer = {
  marginTop: theme.spacing(3),
}

const answersContainer = {
  borderRadius: '4px 4px 0px 0px',
  border: 'solid 1px',
  borderColor: 'black',
}

const chevron = {
  color: theme.palette.grey[600],
}

const contactPopup = {
  width: theme.spacing(8),
  height: theme.spacing(8),
  borderRadius: '50%',
  position: 'absolute' as const,
  transform: 'translate(0%, -80%)',
}

const textContainer = {
  width: '242px',
  height: theme.spacing(10),
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: theme.palette.common.white,
  borderRadius: '4px',
}

const parentPromptContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  position: 'relative',
  borderRadius: theme.spacing(1),
}

const contentContainer = {
  width: '242px',
  height: theme.spacing(10),
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(1),
  display: 'flex',
  justifyContent: 'end',
}

const closeIcon = {
  display: 'flex',
  justifyContent: 'end',
  margin: theme.spacing(1),
  position: 'absolute',
  top: 4,
  right: 4,
}

const floatingParent = {
  position: 'fixed',
  bottom: 104,
  right: 30,
  boxShadow: '0px 0.4px 6px 0px',
  borderRadius: '4px 4px 0px 0px',
  zIndex: 1200,
}

const promptParent = {
  position: 'fixed',
  bottom: 105,
  right: 30,
  borderRadius: '4px',
  boxShadow:
    '0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 2px 2px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.08)',
  zIndex: 100,
}

const chatlogoContainer: React.CSSProperties = {
  position: 'fixed',
  bottom: 24,
  right: 24,
  zIndex: 100,
  cursor: 'pointer',
}

export const classes = {
  lowerContainer,
  topContainer,
  contact,
  chatWithUs,
  parentContainer,
  quickAnswers,
  clickableOption,
  optionContainer,
  answersContainer,
  chevron,
  contactPopup,
  textContainer,
  parentPromptContainer,
  contentContainer,
  closeIcon,
  floatingParent,
  promptParent,
  chatlogoContainer,
}
