import { Link } from 'react-router-dom'
import { navigation } from 'services/navigation'
import moment from 'moment'
import { removeDuplicates } from 'utils'
import SpecialRemarks from './SpecialRemarks'
import Typography from '@mui/material/Typography'

const stripFunctions = (array, roles) => {
  return array?.map((person) => ({
    ...person,
    functions: person.functions.filter(
      (x) => roles.includes(x.function) && x.valid_to === null
    ),
  }))
}

const getSharesForRole = (func) => {
  return func?.shares?.toFixed(2) || func?.shares_interval
}

export const setOwnerData = (ownerRoles, data, classes, country) => {
  const returnValue = []
  const newData = data?.filter((x) => x.functions.length)
  // eslint-disable-next-line no-unused-expressions
  stripFunctions(
    removeDuplicates(
      newData?.map((x) => ({
        ...x,
        shares: x.shares === '100.00%' ? '100%' : x.shares,
      })),
      'name'
    ),
    ownerRoles
  )
    ?.filter((x) => x.functions)
    ?.forEach((person) => {
      removeDuplicates(person.functions, 'function')?.forEach((func) => {
        returnValue.push({
          name: (
            <Link
              style={{ textDecoration: 'none' }}
              to={{
                pathname:
                  person.type === 'COMPANY'
                    ? navigation.creditCheck({
                      ...person.local_organization_id,
                    })
                    : navigation.relationOverview({
                      id: person.personal_id,
                      country: person.country,
                    }),
                state:
                  person.type === 'COMPANY'
                    ? { id: person.local_organization_id.id }
                    : {},
              }}
            >
              <Typography variant="body1">{person?.name}</Typography>
            </Link>
          ),
          position: func?.title,
          ownership: person?.shares
            ? person?.shares
            : getSharesForRole(func)
              ? `${getSharesForRole(func)}%`
              : '-',
          // Fix all that when you get time please
          // And make your data in one place and then use it
          votingRights:
            func?.voting_rights_interval ??
            `${func?.voting_rights
              ? `${parseInt(func?.voting_rights) !== 100
                ? parseFloat(func?.voting_rights).toFixed(2)
                : parseInt(func?.voting_rights)
              }%`
              : '-'
            }` ??
            '+',
          specialRights: (
            <SpecialRemarks
              ownership={person?.special_ownership}
              pep={person.politically_exposed_persons}
            />
          ),
          from: moment(func?.valid_from).format('DD.MM.YYYY'),
          type: func?.function === 'LEGAL OWNER' ? 'legal' : 'real',
        })
      })
    })
  return returnValue
}
