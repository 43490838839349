import * as React from 'react'
import { FilePond } from 'react-filepond'
import * as fp from 'filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import { isNil } from 'ramda'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import './filepond.css'
import 'filepond/dist/filepond.min.css'
import { Box, Modal, Typography, Stack, IconButton } from '@mui/material'
import { ButtonSecondary } from 'components-new'

import { CreditPolicyResponse } from 'types/queries'
import { ReactSetState } from 'types/general'
import { FilePondFile } from 'filepond'
import CloseIcon from '@mui/icons-material/Close'
import { SxProps } from '@mui/system'

fp.registerPlugin(FilePondPluginFileValidateType)

const acceptedFileTypes = [
  'application/json', // Mac CSV
]

type Props = {
  open: boolean
  setOpen: ReactSetState<boolean>
  onFileLoaded: (file: CreditPolicyResponse) => void
}

const container = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 573,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  zIndex: 1,
}

const titleContainer: SxProps = {
  justifyContent: 'center',
  marginBottom: 1,
}
const closeButton: SxProps = {
  alignSelf: 'flex-end',
  fontSize: 29,
  color: 'common.black',
}

const buttonContainer = {}

const ImportDropzone = ({ onFileLoaded, open, setOpen }: Props) => {
  const filePondRef = React.useRef(null)
  const [files, setFiles] = React.useState<any>([])

  const handleSetFiles = (fileArray: FilePondFile[]) => {
    const fileItem = fileArray[0]
    if (fileItem?.file) {
      setFiles([fileItem.file])
    }
  }

  React.useEffect(() => {
    const [file] = files

    if (!isNil(file) && acceptedFileTypes?.includes(file.type)) {
      const fileReader = new FileReader()
      fileReader.readAsBinaryString(file)

      fileReader.onloadend = () => {
        const { result } = fileReader

        onFileLoaded(JSON.parse(result as string))
        setFiles([])
      }
    }
  }, [files])

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={container}>
        <Stack gap={3} paddingX={1} paddingY={3}>
          <Stack sx={titleContainer}>
            <IconButton
              data-cy="side-panel-close-button"
              sx={closeButton}
              onClick={() => setOpen(false)}
            >
              <CloseIcon color="inherit" fontSize="inherit" />
            </IconButton>
            <Typography variant="subtitle1">Import a policy</Typography>
          </Stack>
          <Stack>
            {/* <UploadFileIcon /> */}
            <FormattedMessage id={intl.creditPolicyNew('drag-drop-policy')}>
              {(label) => (
                <FilePond
                  ref={filePondRef}
                  files={files}
                  allowMultiple={false}
                  allowReplace
                  // @ts-ignore
                  labelIdle={label}
                  acceptedFileTypes={acceptedFileTypes}
                  onupdatefiles={handleSetFiles}
                />
              )}
            </FormattedMessage>
          </Stack>
          <Stack direction="row" spacing={5} alignItems="center" sx={buttonContainer}>
            <Typography>or</Typography>
            {/* @ts-ignore */}
            <ButtonSecondary onClick={() => filePondRef.current.browse()}>
              <FormattedMessage id={intl.creditPolicyNew('choose-file')} />
            </ButtonSecondary>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}

export default ImportDropzone
