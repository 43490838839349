import { creditCheckFields, companyInfoFields, monitorTableFields } from 'configs'

/**
 * 1. Do the values have any length
 * 2. Are all values contained in config
 * 3. Does it have companyName field
 */
export const validateMonitorTableFields = (values) => {
  if (!values?.length) {
    throw new Error('You need to pass at least one value!')
  }
  const notContained = values.filter((x) => !monitorTableFields.includes(x))
  if (notContained.length) {
    throw new Error(`'${notContained}' Do not exist in the configuration file.
      If you want to add new fields to the monitoring table update the array in src/configs/userSettings.js`)
  }
  if (!values.includes('companyName')) {
    throw new Error('companyName field does not exist in the array.')
  }
}

/**
 * 1. Do the values have any length
 * 2. Are all values contained in config
 * 3. Are all fields are present
 */
export const validateCreditCheckFields = (values) => {
  if (!values?.length) {
    throw new Error('You need to pass at least one value!')
  }
  const notContained = values.filter((x) => !creditCheckFields.includes(x))
  if (notContained.length) {
    throw new Error(`'${notContained}' Do not exist in the configuration file.
      If you want to add new fields to the monitoring table update the array in src/configs/userSettings.js`)
  }
  const notPresent = creditCheckFields.filter((x) => !values.includes(x))
  if (notPresent.length) {
    throw new Error(`'${notPresent}' Do not exist in the passed array.
        Make sure all values are present!`)
  }
}
/**
 * 1. Do the values have any length
 * 2. Are all values contained in config
 * 3. Are all fields are present
 */
export const validateCompanyInfoFields = (values) => {
  if (!values?.length) {
    throw new Error('You need to pass at least one value!')
  }
  const notContained = values.filter((x) => !companyInfoFields.includes(x))
  if (notContained.length) {
    throw new Error(`'${notContained}' Do not exist in the configuration file.
      If you want to add new fields to the monitoring table update the array in src/configs/userSettings.js`)
  }
  const notPresent = companyInfoFields.filter((x) => !values.includes(x))
  if (notPresent.length) {
    throw new Error(`'${notPresent}' Do not exist in the passed array.
        Make sure all values are present!`)
  }
}

/**
 *
 *
 */
export const validateFutureState = (field, futureState) => {
  if (!Array.isArray(futureState[field])) {
    throw new Error(`${field} needs to be an array!`)
  }
  if (field === companyInfoFields) {
    validateCompanyInfoFields(futureState[field])
  }
  if (field === creditCheckFields) {
    validateCreditCheckFields(futureState[field])
  }
  if (field === monitorTableFields) {
    validateMonitorTableFields(futureState[field])
  }
}
