import { useEffect } from 'react'
import history from 'utils/history'
import { Router, Switch, Route, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IntlProvider } from 'react-intl'
import locale from 'localization'
import { Authentication } from 'components/auth'
import { ErrorBoundary, NotSupported } from 'components'
import { withGA } from 'configs/google-analytics'
import Dashboard from './components/AppShell/Dashboard'
import AuthPages from 'pages/auth/AuthPage'
import numeral from 'numeral'
import NotificationProvider from 'components/NotificationProvider'
import { addDanishDateLocalization } from 'utils/dateUtils'
import { removeResizableObserverError, TS_ERRORS, CypressFixturesTool } from 'utils-new'
import { CreditAutomatorTableProvider } from 'pages/risk-monitoring/widgets/ECPMonitoringTable/context'
import SupportProvider from 'components-new/support-and-chat/SupportProvider'
import SupportAndChat from 'components-new/support-and-chat/SupportAndChat'
/**
 * Ensure we scroll to the top when page changes.
 */
const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const isDev = process.env.NODE_ENV === 'development' && !window.Cypress

const App = () => {
  const userLanguage = useSelector((state) => state.auth?.user?.local?.lang)

  // This hides the ResizeObserver (loop limit) error that is caused by nobody knows what
  useEffect(() => removeResizableObserverError(isDev), [])

  addDanishDateLocalization()
  const determineLanguage = () => {
    const allowedLanguages = ['en', 'da', 'nb', 'sv']
    const lang = userLanguage || navigator.language.replace('-', '_')
    const formattedLanguage = lang.split('_')[0]
    if (!allowedLanguages.includes(formattedLanguage)) {
      return 'en'
    }

    return formattedLanguage
  }

  const language = determineLanguage()
  const isIE = /* @cc_on!@ */ !!document.documentMode

  if (numeral.locales.dans === undefined) {
    numeral.register('locale', 'dans', {
      delimiters: {
        thousands: ' ',
        decimal: ',',
      },
      abbreviations: {
        thousand: 't',
        million: 'mio.',
        billion: 'mia.',
        trillion: 'bio.',
      },
      ordinal: function (number) {
        return number === 1 ? 'er' : 'ème'
      },
      currency: {
        symbol: '€',
      },
    })
  }
  return (
    <IntlProvider locale={language} messages={locale[language]} key={language}>
      {isIE ? (
        <NotSupported />
      ) : (
        <>
          <TS_ERRORS>
            <Router history={history}>
              <ScrollToTop />
              <NotificationProvider>
                <SupportProvider>
                  <Authentication>
                    <Switch>
                      <Route path="/auth">
                        <AuthPages />
                      </Route>
                      <SupportAndChat>
                        <Route path="/">
                          <ErrorBoundary>
                            <CreditAutomatorTableProvider>
                              <Dashboard />
                            </CreditAutomatorTableProvider>
                          </ErrorBoundary>
                        </Route>
                      </SupportAndChat>
                    </Switch>
                  </Authentication>
                </SupportProvider>
              </NotificationProvider>
            </Router>
            {isDev && <CypressFixturesTool />}
          </TS_ERRORS>
        </>
      )}
    </IntlProvider>
  )
}

export default withGA(App)
