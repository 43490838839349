import moment from 'moment'
import risikaAPI from 'services/api/helpers/risikaAPI'

import { paginate } from 'services/helpers/paginate'
/**
 * The amount of items to fetch at a time.
 */
const PAGE_SIZE = 1000

/**
 * Get a page of the monitor list.
 */
const fetchMonitorList = (listId, currency, { from, to }) => {
  const sevenDaysBack = moment().subtract(7, 'days').format('YYYY-MM-DD')
  const now = moment().format('YYYY-MM-DD')

  return risikaAPI
    .post(
      `/list/${listId}`,
      {
        currency,
        dates: {
          from: sevenDaysBack,
          to: now,
        },
        rows: {
          from,
          to,
        },
      },
      {
        microservice: 'monitor',
        appendScoringModel: true,
      }
    )
    .then(({ data }) => {
      return data
    })
}

/**
 * The monitor list to fetch.
 *
 * This is paginated, so it results in multiple requests.
 */
const monitorGetListById = async (listId, currency) => {
  const initialCall = await fetchMonitorList(listId, currency, {
    from: 0,
    to: PAGE_SIZE,
  })

  const pages = paginate(PAGE_SIZE, initialCall.count, { startPage: 1 })

  if (pages.length === 0) {
    return initialCall
  }

  const promises = pages.map((pageRange) => fetchMonitorList(listId, currency, pageRange))

  /**
   * Using safePromises *here* is arguably a BAD PRACTICE, but we don't have a user friendly
   * way to handle when parts of the data is missing (at the moment). We could try and
   * refetch the data a few times, if parts of the list is missing.
   * But we would potentially have to stall it for quite a while.
   */

  const changesWhitelist = [
    'status',
    'score',
    'new_report',
    'management',
    'company_type',
    'name',
    'industry',
    'employees_interval',
    'employees_number',
    'email',
    'phone_number',
    'address',
    'newton',
    'bohr',
    'gazette',
    'industries_secondary',
    'auditor',
    'capital',
    'owners',
    'board_of_directors',
  ]
  return Promise.all(safePromises(promises))
    .then((promises) => {
      let result = initialCall.monitor_list

      promises?.forEach((promise) => {
        result = result.concat(promise?.monitor_list ?? []).map((item) => ({
          ...item,
          changes: item?.changes?.filter((change) =>
            changesWhitelist.includes(change.type)
          ),
        }))
      })
      return { monitor_list: result, count: initialCall.count }
    })
    .then((data) => data)
}

export default monitorGetListById

/***********
 * Helpers *
 ***********/

function safePromises(promises) {
  return promises.map((promise) => promise.catch(() => undefined))
}
