import { faBuilding, faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { ActionDialog } from 'components'
import { dispatch } from 'd3'
import intl from 'localization/components'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import updateNote_dangerous from 'services/api/endpoints/risikaAPI/notes/resources/updateNote_dangerous'
import { navigation } from 'services/navigation'
import { submitFetchListDataById } from 'store_deprecated'
import { classes } from 'pages/risk-monitoring/styles/edit-row-menu'

const EditRowMenu = ({ row, selectedCurrency }) => {
  const internalId = row.original.internalId
  const localId = row.original.localId
  const [internalIdValue, setInternalIdValue] = useState(internalId)
  const listId = useSelector((state) => state.risika.newRiskMonitoring.selectedList)
  const hasChanges = internalIdValue !== internalId

  const handleEditCompany = async () => {
    const note = {
      data: {
        _internal_id: internalIdValue === '' ? null : internalIdValue,
      },
      localId,
    }

    if (!note) return

    try {
      await updateNote_dangerous(note)
      dispatch(submitFetchListDataById(listId, selectedCurrency))
    } catch (error) {
      throw new Error(error)
    }
  }

  return (
    <Box sx={classes.rowActions(row.isHovered)}>
      <ActionDialog
        title={<FormattedMessage id={intl.riskMonitoring('edit-company-title')} />}
        content={() => (
          <TextField
            sx={classes.content}
            id="internal-id-input"
            label={<FormattedMessage id={intl.riskMonitoring('internal-id')} />}
            value={internalIdValue}
            onChange={(event) => setInternalIdValue(event.target.value)}
          />
        )}
        action={handleEditCompany}
        maxWidth="sm"
        fullWidth={true}
        render={(open) => (
          <IconButton onClick={open}>
            <FontAwesomeIcon icon={faPen} />
          </IconButton>
        )}
        agreeText={<FormattedMessage id={intl.generic('save')} />}
        disabled={!hasChanges}
      />
      <Link
        to={{
          pathname: navigation.creditCheck(row.original.localId),
          state: { id: row.original.localId.id },
        }}
      >
        <IconButton>
          <FontAwesomeIcon icon={faBuilding} />
        </IconButton>
      </Link>
    </Box>
  )
}

export default EditRowMenu
