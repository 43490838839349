import Highlight, { HighlightProps } from './Highlight'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import InteractiveHighlight from './InteractiveHighlight'
import ConnectedBankruptcies from './ConnectedBankruptcies'
import PriorDistressHighlight from './PriorDistressHighlights'
import { isNil } from 'ramda'

type HighlightType = HighlightProps & {
  key: string
  data: any
}
export const CompanyHighlights = ({
  highlightsToShow,
  dataCy,
}: {
  highlightsToShow: HighlightType[]
  dataCy: string
}) => {
  if (!highlightsToShow?.length) {
    return null
  }

  return highlightsToShow.map(
    ({ title, message, classification, key: highlight, data, blurred }) => {
      return (
        <div key={highlight} data-cy={dataCy}>
          {highlight === 'connected_bankruptcies' && !isNil(data) ? (
            <InteractiveHighlight
              blurred={blurred}
              text={<FormattedMessage id={intl.generic('show-more')} />}
              content={(open: any, onClose: any) => (
                <ConnectedBankruptcies
                  open={open}
                  onClose={onClose}
                  title={title}
                  data={data}
                />
              )}
            >
              <Highlight
                blurred={blurred}
                title={title}
                message={message}
                classification={classification}
              />
            </InteractiveHighlight>
          ) : highlight === 'prior_distress' && !isNil(data) ? (
            <InteractiveHighlight
              blurred={blurred}
              text={<FormattedMessage id={intl.generic('show-more')} />}
              content={(open: any, onClose: any) => (
                <PriorDistressHighlight
                  open={open}
                  onClose={onClose}
                  title={title}
                  data={data}
                />
              )}
            >
              <Highlight
                blurred={blurred}
                title={title}
                message={message}
                classification={classification}
              />
            </InteractiveHighlight>
          ) : (
            <Highlight
              blurred={blurred}
              title={title}
              message={message}
              classification={classification}
            />
          )}
        </div>
      )
    }
  )
}
