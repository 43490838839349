import { IconButton, Stack } from '@mui/material'
import { StickyHeaderProps } from './StickyHeader.types'
import { Link } from 'react-router-dom'
import { navigation } from 'services/navigation'
import CompanyBasicsHeader from '../CompanyBasicsHeader'
import CancelIcon from '@mui/icons-material/Cancel'
import AutocompleteCompany from 'components-new/inputs/AutocompleteCompany'
import styles from './StickyHeader.styles'

const {
  mainContainer,
  companyInfoContainer,
  iconButtonStyles,
  autoCompleteCompanyContainerStyles,
} = styles

const StickyHeader = ({
  companies,
  showHeader,
  removeCompany,
  autoSelectCompaniesAction,
  pageWidth,
}: StickyHeaderProps) => {
  return (
    <Stack sx={{ ...mainContainer(companies.length, pageWidth, showHeader) }}>
      {companies.map((localId) => (
        <Stack key={localId.id} sx={companyInfoContainer}>
          <Link
            style={{ textAlign: 'center' }}
            to={{ pathname: navigation.creditCheck(localId), state: { id: localId.id } }}
          >
            <CompanyBasicsHeader localId={localId} />
          </Link>
          <IconButton
            sx={{ ...iconButtonStyles(showHeader) }}
            onClick={() => removeCompany(localId)}
          >
            <CancelIcon fontSize="large" color="primary" />
          </IconButton>
        </Stack>
      ))}
      {companies.length < 10 && (
        <Stack key={companies.length} sx={autoCompleteCompanyContainerStyles}>
          <AutocompleteCompany
            disableCountrySelect={!!companies.length}
            action={autoSelectCompaniesAction}
          />
        </Stack>
      )}
    </Stack>
  )
}

export default StickyHeader
