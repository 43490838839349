import { Typography, Box, Stack } from '@mui/material'
import { infoBoxColors } from 'configs/constants/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faExclamation,
  faPlus,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ReactNode } from 'react'

const styles = {
  header: (color: string = infoBoxColors.positive, blurred: boolean) => ({
    alignItems: 'flex-start',
    borderRadius: '0.5rem',
    position: 'relative',
    border: `1px solid`,
    borderLeft: `3rem solid`,
    borderColor: color,
    filter: blurred ? 'blur(3px)' : 'none',
    pointerEvents: blurred ? 'none' : 'auto',
  }),
  title: {
    fontWeight: 600,
  },
  icon: {
    color: 'white',
    height: '1.8rem',
    width: '1.8rem',
    lineHeight: 0,
    marginRight: 1,
  },
  blurred: {
    filter: 'blur(3px)',
    userSelect: 'none',
  },
  notBlurred: {},
  iconContainer: {
    position: 'absolute',
    left: '-24px',
    top: '40%',
    color: 'white',
  },
  highlight_description: (isLoadingComponent: boolean) => ({
    margin: '10px',
    width: isLoadingComponent ? '90%' : 'initial', // This is for the skeleton component
  }),
}

const colorAndIcon = {
  // This is for the skeleton component
  loading: {
    color: 'grey.600',
    icon: faCheck,
  },
  negative: {
    color: infoBoxColors.negative,
    icon: faExclamation,
  },
  neutral: {
    color: infoBoxColors.neutral,
    icon: faCheck,
  },
  positive: {
    color: infoBoxColors.positive,
    icon: faPlus,
  },
  positiveCheck: {
    color: infoBoxColors.positive,
    icon: faCheck,
  },
  default: {
    color: infoBoxColors.default,
    icon: faMinusCircle,
  },
}

export type HighlightProps = {
  title: string | ReactNode
  message: string | ReactNode
  classification:
    | 'positive'
    | 'negative'
    | 'neutral'
    | 'loading'
    | 'default'
    | 'positiveCheck'
  style?: Object
  className?: any
  blurred: boolean
}

const Highlight = ({ title, message, classification, blurred }: HighlightProps) => {
  const { color, icon } = colorAndIcon[classification]
  return (
    <Stack sx={styles.header(color, blurred)}>
      <Box sx={styles.iconContainer}>
        <FontAwesomeIcon
          icon={(icon as IconProp) || (faPlus as IconProp)}
          style={styles.icon}
        />
      </Box>
      <Box sx={styles.highlight_description(classification === 'loading')}>
        <Typography variant="subtitle1" sx={styles.title}>
          {title}
        </Typography>
        <Typography variant="body2">{message}</Typography>
      </Box>
    </Stack>
  )
}

export default Highlight
