import { isNil } from 'ramda'
import {
  ChangeHistoryType,
  ChangeType,
  FunctionChange,
  FraudIndicatorType,
  FraudIndicatorDataPointType,
} from './Change.types'
import moment from 'moment'

// Not my code. Just copy pasted it from Jonas
export const extractChange = (change?: ChangeHistoryType) => {
  if (isNil(change)) {
    return null
  }

  const { value } = change
  // generate name for FunctionChange
  if (!!value && typeof value === 'object' && 'name' in value) {
    const newValue = value as FunctionChange
    const name = newValue?.name
    const role = newValue?.function
    return { ...newValue, name: `${name} (${role})` }
  }
  return value
}
interface RestructureChangesParams {
  changes?: ChangeType[]
  isECP?: boolean
}

export const getRestructureChanges = ({
  changes = [],
  isECP = false,
}: RestructureChangesParams = {}) => {
  const fraudIndicatorChanges =
    changes?.filter(isFraudIndicatorType).map((change) => ({
      ...change,
      date: change.registration_date,
    })) ?? []
  const restChanges = changes?.filter((change) => !isFraudIndicatorType(change)) ?? []

  const dataPoints = fraudIndicatorChanges.reduce(
    (acc: Record<FraudIndicatorDataPointType, string>, curr) => {
      acc[curr.type] = curr.sentence
      return acc
    },
    {} as Record<FraudIndicatorDataPointType, string>
  )

  fraudIndicatorChanges.sort(
    (a, b) =>
      new Date(a.registration_date).getTime() - new Date(b.registration_date).getTime()
  )

  let newRestChanges = restChanges.map((change) => {
    const { new: _new, old } = change

    const from = extractChange(old)
    const to = extractChange(_new)
    const date = change.registration_date

    return {
      ...change,
      from,
      to,
      date,
    }
  })
  if (isECP) {
    newRestChanges = newRestChanges.filter((change) => {
      const date = moment(change.date)
      const now = moment()
      const diff = now.diff(date, 'days')
      return diff <= 7
    })
  }
  const newFraudIndicatorChanges =
    fraudIndicatorChanges.length > 0
      ? [
          {
            ...fraudIndicatorChanges.pop(), // TODO: Taking only the last one for now need to revisit based on feedback
            dataPoints,
          },
        ]
      : []

  return [...newRestChanges, ...newFraudIndicatorChanges]
}

export function isFraudIndicatorType(change: ChangeType): change is FraudIndicatorType {
  const fraudIndicatorValues = new Set<FraudIndicatorDataPointType>([
    'duplicate_statements',
    'distance_to_ceo',
    'frequency_of_address_changes',
    'company_name_changes',
    'companies_from_same_address',
    'profit_vs_employees_abnormalities',
    'ownership_changes',
    'duplicate_statements_across_companies',
    'purchase_of_new_company',
    'prepaid_phone_number',
    'phone_number_registry',
    'financial_statement_abnormalities_year_over',
    'reverse_auditor_bankruptcy_changes',
  ])

  return change
    ? fraudIndicatorValues.has(change.type as FraudIndicatorDataPointType)
    : false
}
