import { Typography, Box, Tooltip } from '@mui/material'
import { compliantStyle } from '../../../../../components-new/data-display/Table/Table.styles'
import { getFraudIcon, IconStatus } from 'utils/getFraudIcon'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ECPAvailableCountries } from 'globalTypes'

type FraundIndicatorCellProps = {
  row: any
}

export const translationKeys = (country: ECPAvailableCountries) => {
  return {
    COMPLIANT: 'compliant-icon-tooltip',
    NO_POLICY: `no-policy-is-set-for-${country}`,
    INACTIVE: 'inactive-icon-tooltip',
    RISK: 'risk-icon-tooltip',
  }
}
const FraundIndicatorCell = ({ row }: FraundIndicatorCellProps) => {
  const compliance = getFraudIcon(row?.original?.fraudIndicators, row.original.statusCode)

  const Icon = compliance.icon
  const companiesCountry = row?.original?.localId?.country

  const tooltips = translationKeys(companiesCountry)

  const DisplayContent = () => (
    <>
      <Tooltip
        title={
          <FormattedMessage
            id={intl.fraudIndicators(
              tooltips[compliance.status as keyof typeof tooltips]
            )}
          />
        }
      >
        <Typography sx={{ ...compliantStyle(compliance.status as IconStatus) }}>
          <Icon sx={{ fontSize: '2.4rem', padding: '0.4rem' }} />
        </Typography>
      </Tooltip>
    </>
  )

  return (
    <Box display="flex" data-cy="body-cell-fraud-indicators">
      <DisplayContent />
    </Box>
  )
}

export default FraundIndicatorCell
