import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { CountryFlagIcon } from 'components-new'
import moment from 'moment'
import { AvailableLanguages, ECPAvailableCountries } from 'globalTypes'
import { ChangeType } from 'components-new/data-display/Table/elements/Changes/Change.types'
import { companyChange, flag, link, textBox } from './RecentChangesTable.styles'
import { useUserSettingsField } from 'services/queries'
import { navigation } from 'services/navigation'
import { Link } from 'react-router-dom'

export type RecentChanges = {
  name: string
  local_organization_id: { country: ECPAvailableCountries; id: string }
  date: string
  sentence: string
  change: ChangeType
}

type CompanyChangeComponentProps = {
  data: RecentChanges
}

export const CompanyChangeComponent = (props: CompanyChangeComponentProps) => {
  const { data } = props
  const { name, date, change, local_organization_id } = data
  const { id } = local_organization_id
  const { data: language } = useUserSettingsField<AvailableLanguages>('language')

  moment.locale(language || 'en')
  const momentDate = moment(date, 'YYYY-MM-DD')
  const relativeDate = momentDate.fromNow()

  return (
    <Box sx={companyChange} data-cy="company-change-component">
      <Box sx={textBox}>
        <Typography variant="subtitle1">{change.sentence}</Typography>
        <ul style={{ paddingLeft: 24 }}>
          <li style={{ height: 4 }}>
            <Typography>{relativeDate}</Typography>
          </li>
        </ul>
      </Box>
      <Box flexDirection={'row'} display={'flex'}>
        <CountryFlagIcon country={local_organization_id.country} styles={flag} />
        &nbsp; &nbsp;
        <Link
          to={{ pathname: navigation.creditCheck(local_organization_id), state: { id } }}
          style={link}
          data-cy={`link-to-${name}`}
        >
          <Typography variant="body2">
            {name} ({local_organization_id.id})
          </Typography>
        </Link>
      </Box>
    </Box>
  )
}
