import { useQuery } from 'react-query'
import { fraudIndicators } from 'services/api'
import { isCallAllowed } from 'configs'
import { LocalId } from 'globalTypes'

export function useFraudIndicators(localId: LocalId, isEnabled: boolean) {
  return useQuery(
    ['fraudIndicators', localId.id, localId.country],
    () => fraudIndicators(localId),
    {
      enabled: !!isCallAllowed('fraudIndicators', localId.country) && !!isEnabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}
