import intl from 'localization/components'
import { handleComponentRenderRestrictions } from 'services/helpers'

type CompanyExportInfo = {
  companyId: string
  companyName: string
  companyType: string
  status: string
  dateOfIncorporation: string
  score: string
  fraudIndicators: string
  creditMax: number
  phone: string
  street: string
  number: string
  coname: string
  zipcode: string
  city: string
  email: string
  municipality: string
  postdistrict: string
  country: string
  currency: string
  equity: string
  profitLoss: string
  solidity: string
  liquidity: string
  industryCode: string
  industryDescription: string
  employees: string
  internalId: string
  startDate: string
  endDate: string
  capital: string
  EBITDA: string
  ebit: string
  ordinaryProfit: string
  shortTermDebt: string
  longTermDebt: string
  shortTermTradePayables: string
  shortTermReceivablesFromSalesAndServices: string
}

const formatEmployees = (employeesInterval: string) => {
  if (employeesInterval === '1000-999999') {
    return '1000+'
  }

  return employeesInterval
}

export const translateAndOrderCompanyData = (
  mappedCompaniesArr: CompanyExportInfo[],
  plan: string,
  messages: Record<string, string>,
  shouldRenderFraudIndicators: boolean = false
) => {
  return mappedCompaniesArr.map((row) => {
    const exportedRow = {
      [messages[intl.generic('company-id')]]: row.companyId,
      [messages[intl.generic('company-name')]]: row.companyName,
      [messages[intl.generic('company-type')]]: row.companyType,
      [messages[intl.generic('date-of-incorporation')]]: row.dateOfIncorporation,
      [messages[intl.generic('email')]]: row.email,
      [messages[intl.generic('status')]]: row.status,
      [messages[intl.generic('risika-score')]]: handleComponentRenderRestrictions({
        component: 'risika-score',
        local_id: {
          id: row.companyId,
        },
        plan,
      })
        ? row.score
        : '',
      [messages[intl.generic('fraud-indicators')]]: parseInt(row.fraudIndicators)
        ? messages[intl.generic('fraud-indicators-risk-detected')]
        : messages[intl.generic('fraud-indicators-no-risk-detected')],
      [messages[intl.generic('phone')]]: row.phone,
      [messages[intl.generic('street')]]: row.street,
      [messages[intl.generic('street-number')]]: row.number,
      [messages[intl.generic('coname')]]: row.coname,
      [messages[intl.generic('zip-code')]]: row.zipcode,
      [messages[intl.generic('city')]]: row.city,
      [messages[intl.generic('municipality')]]: row.municipality,
      [messages[intl.generic('postal-district')]]: row.postdistrict,
      [messages[intl.generic('country')]]: row.country,
      [messages[intl.generic('number-of-employees')]]: formatEmployees(row.employees),
      [messages[intl.generic('industry-code')]]: row.industryCode,
      [messages[intl.generic('industry-description')]]: row.industryDescription,
      [messages[intl.generic('currency')]]: row.currency,
      [messages[intl.generic('credit-max')]]: handleComponentRenderRestrictions({
        component: 'risika-score',
        local_id: {
          id: row.companyId,
        },
        plan,
      })
        ? row.creditMax
        : '',
      [messages[intl.generic('equity')]]: row.equity,
      [messages[intl.generic('profit-loss')]]: row.profitLoss,
      [messages[intl.generic('solidity')]]: row.solidity,
      [messages[intl.generic('liquidity')]]: row.liquidity,
      [messages[intl.generic('internal-id')]]: row.internalId,

      [messages[intl.keyFinancialFigures('capital')]]: row.capital,
      [messages[intl.keyFinancialFigures('EBITDA')]]: row.EBITDA,
      [messages[intl.keyFinancialFigures('ebit')]]: row.ebit,
      [messages[intl.keyFinancialFigures('ordinaryProfit')]]: row.ordinaryProfit,
      [messages[intl.keyFinancialFigures('shortTermDebt')]]: row.shortTermDebt,
      [messages[intl.keyFinancialFigures('longTermDebt')]]: row.longTermDebt,
      [messages[intl.keyFinancialFigures('shortTermTradePayables')]]:
        row.shortTermTradePayables,
      [messages[intl.keyFinancialFigures('shortTermReceivablesFromSalesAndServices')]]:
        row.shortTermReceivablesFromSalesAndServices,
      [messages[intl.keyFinancialFigures('start-date')]]: row.startDate,
      [messages[intl.keyFinancialFigures('end-date')]]: row.endDate,
    }
    if (shouldRenderFraudIndicators) {
      exportedRow[messages[intl.generic('fraud-indicators')]] = parseInt(
        row.fraudIndicators
      )
        ? messages[intl.generic('fraud-indicators-risk-detected')]
        : messages[intl.generic('fraud-indicators-no-risk-detected')]
    }
    return exportedRow
  })
}
