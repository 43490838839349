import CheckIcon from '@mui/icons-material/Check'
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator'
import ErrorIcon from '@mui/icons-material/PriorityHigh'
import HorizontalRuleSharpIcon from '@mui/icons-material/HorizontalRuleSharp'

export type IconStatus = 'ACTIVE' | 'COMPLIANT' | 'INACTIVE' | 'NO_POLICY'
export const getFraudIcon = (indicatorCount: null | number, statusCode: IconStatus) => {
  if (indicatorCount === 0) {
    return { icon: CheckIcon, status: 'COMPLIANT' }
  }

  if (indicatorCount === null && statusCode === 'ACTIVE') {
    return {
      icon: RemoveModeratorIcon,
      status: 'NO_POLICY',
    }
  }

  if (statusCode !== 'ACTIVE') {
    return { icon: HorizontalRuleSharpIcon, status: 'INACTIVE' }
  }

  return { icon: ErrorIcon, status: 'RISK' }
}
