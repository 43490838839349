import React, { useState } from 'react'
import { FraudIndicatorsPromotion } from '../FraudIndicatorsPromotion'
import { useAccountStatus } from 'services/queries'
import { FraudIndicatorsModal } from '../Modals/FraudIndicatorsModal'

export const FraudIndicatorsCTA = () => {
  const accountStatusQuery = useAccountStatus()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const userHasFraudIndicators =
    // @ts-ignore - this will be resolved once ENG2-1131 is merged in
    accountStatusQuery?.data?.rights?.global?.fraud_indicators

  if (userHasFraudIndicators) {
    return null
  }

  return (
    <>
      <FraudIndicatorsPromotion onClick={() => setIsModalOpen(true)} />
      <FraudIndicatorsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  )
}
