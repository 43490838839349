import { PaletteColorOptions } from '@mui/material'
import { themeObject } from 'styles-new/mui5-transition/theme'

export const riskColors = {
  high: themeObject?.palette?.error?.[700 as keyof PaletteColorOptions], // 1,2

  mediumHigh: themeObject?.palette?.warning?.[900 as keyof PaletteColorOptions], // 3,4
  medium: themeObject?.palette?.warning?.[700 as keyof PaletteColorOptions], // 5,6
  mediumLow: themeObject?.palette?.success?.[500 as keyof PaletteColorOptions], // 7,8
  low: themeObject?.palette?.success?.[700 as keyof PaletteColorOptions], // 9,10
  unknown: themeObject?.palette?.grey?.[700 as keyof PaletteColorOptions],
}

export const infoBoxColors = {
  negative: themeObject?.palette?.error?.[700 as keyof PaletteColorOptions],
  neutral: themeObject?.palette?.warning?.[700 as keyof PaletteColorOptions],
  positive: themeObject?.palette?.success?.[500 as keyof PaletteColorOptions],
  default: themeObject?.palette?.grey?.[600 as keyof PaletteColorOptions],
}

export const textColors = {
  primary: themeObject?.palette?.grey?.[800 as keyof PaletteColorOptions],
  secondary: themeObject?.palette?.grey?.[600 as keyof PaletteColorOptions],
}

export const chartColors = {
  1: themeObject?.palette?.primary?.[500 as keyof PaletteColorOptions],
  2: themeObject?.palette?.primary?.[700 as keyof PaletteColorOptions],
  3: themeObject?.palette?.secondary?.[300 as keyof PaletteColorOptions],
  4: themeObject?.palette?.secondary?.[700 as keyof PaletteColorOptions],
  5: themeObject?.palette?.success?.[300 as keyof PaletteColorOptions],
  6: themeObject?.palette?.error?.[300 as keyof PaletteColorOptions],
  7: themeObject?.palette?.info?.[300 as keyof PaletteColorOptions],
  8: themeObject?.palette?.warning?.[300 as keyof PaletteColorOptions],
  9: themeObject?.palette?.grey?.[500 as keyof PaletteColorOptions],
  10: themeObject?.palette?.success?.[900 as keyof PaletteColorOptions],
  11: themeObject?.palette?.error?.[900 as keyof PaletteColorOptions],
  12: themeObject?.palette?.info?.[900 as keyof PaletteColorOptions],
  13: themeObject?.palette?.warning?.[900 as keyof PaletteColorOptions],
  14: themeObject?.palette?.grey?.[300 as keyof PaletteColorOptions],
  15: themeObject?.palette?.grey?.[700 as keyof PaletteColorOptions],
  16: themeObject?.palette?.primary?.[300 as keyof PaletteColorOptions],
}
