import { useEffect, useState } from 'react'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorBoundary, HistoryHeader, Loader } from 'components'
import intl from 'localization/components'
import SubscriptionControlPanel from './scenes/SubscriptionControlPanel'
import AddNewList from './scenes/AddNewList'
import { Analytics } from './scenes/Analytics'
import MonitorTable from './scenes/MonitorTable'
import HorizontalTabList from './scenes/HorizontalTabList/HorizontalTabList'
import { apiParameters } from './widgets/ECPMonitoringTable/ECPMonitoringTable.model'
import { riskMonitoringFormatError } from './controller'
import { ErrorPageSimple } from '../../components-new'
import { ReduxRootState } from 'store_deprecated/types'
import { Monitor } from 'risika-api-response-types'
import { createInitialFilters } from 'pages/observational-lists/ObservationalListsPage.controller'
import { automatorComplianceInitialFilters } from 'pages/observational-lists/ObservationalListsPage.model'
import { riskIntelligenceApiKeys as apiKeys } from 'configs/constants/api'
import { calculateActiveFilters } from './widgets/ECPMonitoringTable/ECPMonitoringTable.controller'
import { TableDataAPIType } from 'components-new/data-display/Table/Table.types'
import {
  useUserSelf,
  useMonitorShowList,
  usePaginatedMonitoringList,
  useMonitoringStatistic,
  useAccountStatus,
  useUserSettings,
} from 'services/queries'
import {
  clearError,
  setSelectedList,
  submitFetchListEntriesById,
  submitShowAllMonitoringLists,
} from 'store_deprecated'
import { useCreditAutomatorTableContext } from './widgets/ECPMonitoringTable/context'
import { usePolicyViolations } from 'services/queries/usePolicyViolations'
import { useHighlightsFilters } from 'services/queries/useFilterHighlights'
import CreateFirstMonitoringList from './widgets/CreateFirstMonitoringList'
import { ECPMonitoringTable } from './widgets/ECPMonitoringTable/ECPMonitoringTable'

const RiskMonitoringPage = () => {
  const { messages } = useIntl()
  const { data: currentUserInfo, isLoading: isUserDataLoading } = useUserSelf()
  const userSettingsQuery = useUserSettings()
  const selectedCurrency = userSettingsQuery.data?.selectedCurrency?.currency || 'DKK'

  const { data: accountStatus, isLoading: isStatusLoading } = useAccountStatus()
  const isUserEcp = !!accountStatus?.rights?.global?.enterprise_credit_policy
  const loading =
    useSelector((state: ReduxRootState) => state.risika.newRiskMonitoring.loading) ||
    isStatusLoading
  const lists = useSelector(
    (state: ReduxRootState) => state.risika.newRiskMonitoring.lists
  )
  const selectedList = useSelector(
    (state: ReduxRootState) => state.risika.newRiskMonitoring.selectedList
  )

  const { isError, error } = useSelector(
    (state: ReduxRootState) => state.risika.newRiskMonitoring
  )
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { data: showListsData, isLoading: isShowListsLoading } = useMonitorShowList()

  const selectedListName =
    showListsData?.find((list) => list.list_id === selectedList)?.name || ''
  const [tableFilters, setTableFilters] = useState({})
  const { actions, state } = useCreditAutomatorTableContext()
  const { page, perPageLimit, sortBy } = state

  if (state.listId !== selectedList) {
    actions.updateListId(selectedList)
  }

  const {
    data: pointsOfAttention,
    isSuccess: highlightsSuccess,
    isLoading: isHighlightsLoading,
  } = useHighlightsFilters()

  const { data: violations } = usePolicyViolations()

  useEffect(() => {
    actions.updatePage(1)
  }, [selectedList])

  const listQueryParams = {
    ...apiParameters,
    page,
    sort_by: sortBy,
    limit: perPageLimit,
    filter_on: calculateActiveFilters(tableFilters),
  }
  const entireDataQueryParams = {
    dates: apiParameters.dates,
    sort_by: sortBy,
    filter_on: calculateActiveFilters(tableFilters),
  }

  const {
    data: paginatedData,
    isLoading,
    isError: isErrorPaginatedMonitoringList,
    refetch: refetchMonitoringData,
    isFetching,
  } = usePaginatedMonitoringList(Number(selectedList), listQueryParams, page)

  const { data: entireData } = usePaginatedMonitoringList(
    Number(selectedList),
    entireDataQueryParams,
    0
  )

  const monitoringStatisticQuery =
    useMonitoringStatistic<Monitor.ListStatisticsCompliance>({
      parameters: { list_id: Number(selectedList) },
      endpoint: 'compliance',
      staleTime: 6000,
    })

  const isFiltersLoading = monitoringStatisticQuery.isLoading || isHighlightsLoading

  useEffect(() => {
    if (!Object.keys(lists)?.length) {
      if (showListsData?.length) {
        dispatch(submitShowAllMonitoringLists())
      }
    }
  }, [dispatch, lists, showListsData?.length])

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(riskMonitoringFormatError(error), { variant: 'error' })
      dispatch(clearError())
    }
  }, [isError, error, enqueueSnackbar, dispatch])

  // handles displaying list
  useEffect(() => {
    const list = lists[selectedList]
    if (
      list?.status === 'IDLE' &&
      !isLoading &&
      paginatedData?.credit_policy_used !== 'credit_automator'
    ) {
      dispatch(submitFetchListEntriesById(selectedList, selectedCurrency))
    }
    if (list?.status === 'FAILURE') {
      enqueueSnackbar(
        <FormattedMessage
          id={intl.generic('failed-to-fetch-list')}
          values={{ name: list.name }}
        />
      )
    }
    // If the list with ID '0' is hidden show the next lowest ID.
    if (
      selectedList === 0 &&
      !Object.keys(lists).includes('0') &&
      Object.keys(lists).length
    ) {
      dispatch(setSelectedList(Math.min(...Object.keys(lists).map((x) => parseInt(x)))))
    }
  }, [
    selectedList,
    lists,
    dispatch,
    enqueueSnackbar,
    isLoading,
    paginatedData?.credit_policy_used,
    selectedCurrency,
  ])

  // handles filter changes
  useEffect(() => {
    if (highlightsSuccess) {
      if (_.isEmpty(tableFilters)) {
        const poaList = pointsOfAttention.highlights
        const initialFilters = createInitialFilters(
          violations?.policy_violations,
          poaList,
          monitoringStatisticQuery.isSuccess
        )

        setTableFilters({
          ...initialFilters,
          [apiKeys.creditAutomatorResult]: automatorComplianceInitialFilters,
        })
        actions.updatePage(1)
      }
    }
  }, [
    actions,
    highlightsSuccess,
    monitoringStatisticQuery,
    pointsOfAttention?.highlights,
    tableFilters,
    violations?.policy_violations,
  ])

  if (loading || isShowListsLoading || isUserDataLoading) {
    return <Loader />
  }

  if (showListsData?.length === 0 && currentUserInfo) {
    return (
      <>
        <HistoryHeader
          title={messages[intl.riskMonitoring('title')] as string}
          historyKey="monitoring"
          location="monitoring"
        >
          <></>
          <AddNewList currentUserInfo={currentUserInfo} customListButton={null} />
        </HistoryHeader>
        <CreateFirstMonitoringList currentUserInfo={currentUserInfo} />
      </>
    )
  }

  const renderTable = (data?: TableDataAPIType) => {
    if (!data || (isUserEcp && _.isEmpty(tableFilters))) {
      if (isError) {
        return (
          <ErrorPageSimple
            content={
              <FormattedMessage id={intl.riskMonitoringNew('error-loading-page')} />
            }
          />
        )
      }
      return null
    }

    return isUserEcp ? (
      <ECPMonitoringTable
        data={paginatedData}
        entireData={entireData}
        listId={selectedList}
        listName={selectedListName}
        selectedCurrency={selectedCurrency}
        tableFilters={tableFilters}
        setTableFilters={setTableFilters}
        isLoading={isLoading || isFetching}
        isFiltersLoading={isFiltersLoading}
        isError={isErrorPaginatedMonitoringList}
        refetchData={refetchMonitoringData}
      />
    ) : (
      <MonitorTable
        entries={lists[selectedList]?.entries ?? []}
        name={selectedListName}
        selectedCurrency={selectedCurrency}
      />
    )
  }

  return (
    // @ts-expect-error
    <ErrorBoundary page="risk-monitoring">
      <HistoryHeader
        title={messages[intl.riskMonitoring('title')] as string}
        historyKey="monitoring"
        location="monitoring"
      >
        <SubscriptionControlPanel />
        <AddNewList currentUserInfo={currentUserInfo} customListButton={null} />
      </HistoryHeader>
      <HorizontalTabList />
      <Analytics
        selectedList={lists[selectedList]}
        paginatedListLoading={isLoading}
        isUsingCreditAutomator={isUserEcp}
      />
      {/* @ts-expect-error */}
      <ErrorBoundary page="risk-monitoring-table">
        {renderTable(
          // @ts-expect-error // Not touching that, Redux types are being phased out
          paginatedData ?? lists[selectedList]?.entries
        )}
      </ErrorBoundary>
    </ErrorBoundary>
  )
}

export default RiskMonitoringPage
