import { Typography, Tooltip, Box } from '@mui/material'
import { navigation } from 'services/navigation'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import CompanyNameTooltipBullets from './CompanyNameTooltipBullets'
import { classes } from 'pages/risk-monitoring/styles/company-name-cell'
import { useHistory } from 'react-router-dom'

const CompanyNameCell = ({ name, address, localId, creditPolicy }) => {
  const conflicts = creditPolicy?.conflicts
  const history = useHistory()

  return (
    <Tooltip
      placement="top"
      title={conflicts?.length ? <CompanyNameTooltipBullets data={conflicts} /> : ''}
      PopperProps={classes.popperProps}
    >
      <Box
        sx={classes.conflictColor(Boolean(conflicts?.length))}
        onClick={() => {
          history.push(navigation.creditCheck(localId), { id: localId.id })
        }}
      >
        <Typography variant="subtitle1">{name}</Typography>
        <Typography variant="body2">
          {address ?? <FormattedMessage id={intl.generic('missing-address')} />}
        </Typography>
      </Box>
    </Tooltip>
  )
}

export default CompanyNameCell
