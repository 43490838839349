import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Box,
} from '@mui/material'
import { colors } from 'configs'
import { makeStyles } from '@mui/styles'
import { HumanDate } from 'components'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { navigation } from 'services/navigation'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useQueryClient } from 'react-query'
import { ErrorPageSimple } from 'components-new'
import { useBankruptcyDetails } from 'services/queries/useBanruptcyDetails'
import { GppMaybe as GppMaybeIcon } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  companyItem: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  error: { margin: '12px 0px 24px' },
}))

const ConnectedBankruptcies = ({ open, onClose, title, data }) => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const userSettings = queryClient.getQueryData('getUserSettings')
  const country = userSettings?.selectedCountry
  const { data: bankruptcyData, isLoading } = useBankruptcyDetails({
    bankruptcies: data,
    country,
    enabled: open,
  })

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle
        disableTypography
        style={{ backgroundColor: colors?.risika, color: 'white' }}
      >
        <Typography variant="h4">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        {!isLoading && !bankruptcyData?.length ? (
          <Box className={classes.error}>
            <ErrorPageSimple
              title={<FormattedMessage id={intl.errors('service-unavailable')} />}
              content={
                <FormattedMessage id={intl.errors('service-unavailable-description')} />
              }
              icon={
                <GppMaybeIcon color="warning" sx={{ width: '6rem', height: '6rem' }} />
              }
            />
          </Box>
        ) : (
          <List>
            {bankruptcyData?.map(({ person, companies }, index) => (
              <React.Fragment key={person.id}>
                <ListItem className={classes.listItem}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Link
                        className={classes.link}
                        to={navigation.relationOverview({
                          id: person.id,
                          country,
                        })}
                      >
                        <Typography variant="h6">{person.name}</Typography>
                      </Link>
                    }
                    secondary={companies.map((company) => (
                      <Box
                        key={`${person.name}${company.local_organization_id.id}`}
                        className={classes.companyItem}
                      >
                        <Link
                          className={classes.link}
                          to={{
                            pathname: navigation.creditCheck(
                              company.local_organization_id
                            ),
                            state: { id: company.local_organization_id.id },
                          }}
                          onClick={onClose}
                        >
                          <Typography style={{ fontWeight: 600 }} variant="body1">
                            {company.company_name}
                          </Typography>
                        </Link>
                        <Typography variant="body2">
                          {company.functions[0].title}
                        </Typography>
                        <Typography variant="body2">
                          <HumanDate
                            date={moment(company.functions[0].valid_from)}
                            format="LL"
                          />
                          {' – '}
                          {Boolean(company.functions[0].valid_to?.length) > 0 ? (
                            <HumanDate
                              date={moment(company.functions[0].valid_to)}
                              format="LL"
                            />
                          ) : (
                            <FormattedMessage
                              id={intl.mainRecommendation('undergoing-bankruptcy')}
                            />
                          )}
                        </Typography>
                      </Box>
                    ))}
                  />
                </ListItem>
                {index !== bankruptcyData.length - 1 ? <Divider /> : null}
              </React.Fragment>
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ConnectedBankruptcies
