export const monitorTableFields = [
  'companyName',
  'localId',
  'creditMax',
  'score',
  'fraudIndicators',
  'country',
  'status',
  'profitLoss',
  'equity',
  'employeeInterval',
  'currency',
  'companyType',
  'solidity',
  'liquidity',
  'dateOfIncorporation',
  'internalId',
  'capital',
]

export const companyInfoFields = [
  'company-name',
  'company-id',
  'email',
  'phone',
  'webpage',
  'address',
  'ad-protection',
  'registered-for-vat',
  'ean',
  'company-type',
  'date-of-establishment',
  'founders_auditors',
  'auditors',
  'auditor_selected',
  'financial_year',
  'bank',
  'share-capital',
  'powers-to-bind',
  'purpose',
  'number-of-employees',
  'industry',
  'production-units',
  'listed',
  'secondary-names',
  'status',
  'internal-id',
  'property',
]

export const creditCheckFields = [
  'general-company-information',
  'key-financial-figures',
  'relevant-financial-ratios',
  'owners',
  'management',
  'map',
]
