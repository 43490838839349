import callBackendAPI from 'services/api/helpers/backendAPI'
import {
  MonitorTableType,
  MonitorTableEnum,
  CreditCheckType,
  CreditCheckKeyEnum,
  CompanyInfoType,
  CompanyInfoKeyEnum,
  CreditCheckSidebarType,
  CreditCheckSidebarEnum,
  AvailableCountriesCapital,
} from './getUserSettings'
import { AvailableCountries, AvailableCurrencies } from 'globalTypes'
import { AvailableCountriesForCurrency, CurrencyObj } from '../../../types'

type SelectedCurrencyFromSettings = {
  currency: AvailableCurrencies
  countryCode: AvailableCountriesCapital
}

type RequestData = {
  monitor_table: MonitorTableType[]
  credit_check: CreditCheckType[]
  company_info: CompanyInfoType[]
  credit_check_sidebar: CreditCheckSidebarType[]
  selected_currency: CurrencyObj
  selected_country: AvailableCountries
  export_options_filtering?: any
  export_options_monitoring?: any
}

type Settings = {
  monitorTableFields: MonitorTableEnum[]
  creditCheckFields: CreditCheckKeyEnum[]
  companyInfoFields: CompanyInfoKeyEnum[]
  creditCheckSidebarPos: CreditCheckSidebarEnum[]
  selectedCurrency: SelectedCurrencyFromSettings
  selectedCountry: AvailableCountries
  exportOptionsFiltering?: any
  exportOptionsMonitoring?: any
}

type ResponseData = {
  data: RequestData
}

const fromCountryToCurrencyCountry = (country: AvailableCountries) => {
  const euroZone = [
    'FI',
    'FR',
    'DE',
    'IT',
    'ES',
    'NL',
    'AT',
    'BE',
    'IE',
    'GR',
    'PT',
    'SI',
    'SK',
    'EE',
    'LV',
    'LT',
    'CY',
    'MT',
  ]
  if (euroZone.includes(country)) {
    return 'EU'
  }
  if (country === 'UK') {
    return 'GB'
  }
  return country as AvailableCountriesForCurrency
}
function handleSetFieldsToData(settings: Settings) {
  const data: RequestData = {
    monitor_table: settings.monitorTableFields.map((x) => ({
      key: x,
      visible: true,
    })),
    credit_check: settings.creditCheckFields.map((x) => ({
      key: x,
      visible: true,
    })),
    company_info: settings.companyInfoFields.map((x) => ({
      key: x,
      visible: true,
    })),
    credit_check_sidebar: settings.creditCheckSidebarPos.map((x) => ({
      key: x,
      visible: true,
    })),
    selected_currency: {
      currency: settings.selectedCurrency.currency,
      country: fromCountryToCurrencyCountry(settings.selectedCurrency.countryCode),
    },
    selected_country: settings.selectedCountry,
  }

  if (settings.exportOptionsFiltering) {
    data.export_options_filtering = settings.exportOptionsFiltering
  }
  if (settings.exportOptionsMonitoring) {
    data.export_options_monitoring = settings.exportOptionsMonitoring
  }
  return data
}

// Updates user settings in the api
const updateUserSettings = (settings: Settings) => {
  const data = handleSetFieldsToData(settings)
  return callBackendAPI('/user/settings', {
    method: 'put',
    data,
  }).then(({ data }: ResponseData) => {
    return {
      monitorTableFields: data.monitor_table.filter((x) => x.visible).map((x) => x.key),
      creditCheckFields: data.credit_check.map((x) => x.key),
      companyInfoFields: data.company_info.map((x) => x.key),
      creditCheckSidebarPos: data.credit_check_sidebar.map((x) => x.key),
      selectedCurrency: {
        currency: data.selected_currency.currency,
        countryCode: data.selected_currency.country,
      },
      selectedCountry: data.selected_country,
      exportOptionsFiltering: data.export_options_filtering,
      exportOptionsMonitoring: data.export_options_monitoring,
    }
  })
}

export default updateUserSettings
