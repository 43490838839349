import { useMutation, useQueryClient } from 'react-query'
import { updateFraudIndicatorsPolicy } from 'services/api'
import {
  ResponseData,
  RequestData,
} from 'services/api/endpoints/risikaAPI/updateFraudIndicatorsPolicy'
import { WebApiError } from 'types/general'

type FraudIndicatorsPolicyUpdateProps = {
  onSuccess?: () => void
  onError?: () => void
}

const useFraudIndicatorsPolicyUpdate = ({
  onSuccess,
  onError,
}: FraudIndicatorsPolicyUpdateProps) => {
  const queryClient = useQueryClient()

  const mutation = useMutation<ResponseData, WebApiError, RequestData>(
    updateFraudIndicatorsPolicy,
    {
      onSuccess: (_, variables) => {
        onSuccess?.()
        queryClient.invalidateQueries(['getFraudIndicatorsPolicy', variables.country])
      },
      onError: (err) => {
        onError?.()
        console.error(err)
      },
    }
  )

  return { updatePolicy: mutation.mutate, ...mutation }
}

export { useFraudIndicatorsPolicyUpdate }
