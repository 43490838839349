import { riskIntelligenceApiKeys as apiKeys } from '../../../../configs/constants/api'
import numeral from 'numeral'
import lodash from 'lodash'
import {
  ColumnsDefinition,
  Company,
  TableRowData,
} from 'components-new/data-display/Table/Table.types'
import { formatToUniversalDate } from 'utils/dateUtils'

export const formatData = (
  data: Company[],
  columnsDefinition: ColumnsDefinition
): TableRowData[] => {
  return data.map((item) => {
    const rowsByColumnDefinition = columnsDefinition.reduce(
      (accumulator: any, column: any) => {
        const currency = item.financial_key_figures.currency
        // @ts-ignore
        let value = item[column.key] ?? '*'

        if (column.sub_key) {
          if (column.sub_key === 'id') {
            // @ts-ignore
            value = item[column.key][column.sub_key] ?? '*'
          } else {
            // @ts-ignore
            value = item[column.sub_key][column.key] ?? '*'
          }
        }

        if (typeof value === 'boolean') {
          value = value ? apiKeys.compliant : apiKeys.nonCompliant
        }

        if (column.key === 'date_of_incorporation') {
          value = formatToUniversalDate(value)
        }

        if (column.type === 'number_currency') {
          const formattedValue = numeral(value).format('0,0')
          // This case handles the currency set in the enterprise credit policy (credit automator)
          if (value === '*') {
            value = 'N/A'
          } else if (column.sub_key === 'credit_policy_credit_max') {
            const policyCurrency = item.credit_policy_credit_max.policy_currency
            value = `${formattedValue} ${policyCurrency}`
          } else {
            value = `${formattedValue} ${currency}`
          }
        }

        if (column.key === 'solidity' || column.key === 'liquidity') {
          value = isNaN(value) ? '*' : `${(value * 100).toFixed(3)}%`
        }

        if (column.key === 'fraud_indicators_count') {
          value = item.fraud_indicators_count
        }

        if (column.key === apiKeys.employeesNumber) {
          value = numeral(value).format('0,0')
          accumulator.employment_trend = item.employment_trend
        } else if (column.key === apiKeys.profitLoss) {
          accumulator.profit_loss_trend = item.profit_loss_trend
        } else if (column.key === apiKeys.equity) {
          accumulator.equity_trend = item.equity_trend
        }
        if (!accumulator.original) accumulator.original = item
        accumulator[column.key] = value

        return accumulator
      },
      {}
    )
    // Adding changes here so we maintain the columnsDefinition object as it is
    return {
      ...rowsByColumnDefinition,
      changes: item.changes,
    }
  })
}

export const calculateActiveFilters = (tableFilterData: any) => {
  const tableFilterClone = lodash.cloneDeep(tableFilterData)
  // Evaluate all dropdown filters
  const newFilter = Object.keys(tableFilterClone).reduce(
    (accumulator: any, category: string) => {
      const filterArray = Object.keys(tableFilterClone[category])
        .filter((categoryKey: string) => tableFilterClone[category][categoryKey].checked)
        .map((categoryKey: string) => {
          if (categoryKey === apiKeys.compliant) return true
          if (categoryKey === apiKeys.nonCompliant) return false
          if (category === apiKeys.highlights) {
            return {
              category: tableFilterClone[category][categoryKey].category,
              value: tableFilterClone[category][categoryKey].value,
            }
          }
          return categoryKey
        })
      if (filterArray.length > 0) {
        accumulator[category] = filterArray
      }
      return accumulator
    },
    {}
  )
  // Evaluate free search filter
  const searchTerm = tableFilterClone[apiKeys.freeSearch] ?? ''
  if (searchTerm.length > 0) newFilter[apiKeys.freeSearch] = searchTerm
  return newFilter
}
