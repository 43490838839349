import { authRoles } from 'components/auth'
import { navigation } from 'services/navigation'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie } from '@fortawesome/free-solid-svg-icons'

import HomeIcon from '@mui/icons-material/Home'
import DomainIcon from '@mui/icons-material/Domain'
import NotificationsIcon from '@mui/icons-material/Notifications'
import BarChartIcon from '@mui/icons-material/BarChart'
import PeopleIcon from '@mui/icons-material/People'
import PolicyIcon from '@mui/icons-material/Policy'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import HelpIcon from '@mui/icons-material/Help'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import ErrorIcon from '@mui/icons-material/Error'
import { Icon } from '@fortawesome/fontawesome-svg-core'

const iconStyles = { fontSize: 21 }
export const FAQ_LINK = 'https://help.risika.dk/faqs'

export default [
  {
    id: 'dashboard',
    title: 'dashboard',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'home-component',
        title: 'home',
        type: 'item',
        icon: <HomeIcon sx={iconStyles} />,
        auth: authRoles.user,
        url: navigation.home(),
      },
      {
        id: 'creditcheck-component',
        canBeDisabled: true,
        title: 'credit-check',
        type: 'item',
        icon: <DomainIcon sx={iconStyles} />,
        auth: authRoles.user,
        url: navigation.creditCheck(),
        subUrls: [navigation.companyHierarchy()],
      },
      {
        id: 'riskmonitoring-component',
        title: 'risk-monitoring',
        type: 'item',
        icon: <NotificationsIcon sx={iconStyles} />,
        auth: authRoles.user,
        url: navigation.riskMonitoring(),
        permissions: 'allow_monitoring',
        subUrls: [navigation.observationalLists()],
      },
      // {
      //   id: 'company-hierarchy-component',
      //   canBeDisabled: true,
      //   title: 'company-hierarchy',
      //   type: 'item',
      //   auth: authRoles.user,
      //   icon: <Business />,
      //   url: navigation.companyHierarchy(),
      // },
      {
        id: 'company-comparison-component',
        title: 'company-comparison',
        type: 'item',
        icon: <BarChartIcon sx={iconStyles} />,
        auth: authRoles.user,
        url: navigation.companyComparison(),
      },
      {
        id: 'relationoverview-component',
        canBeDisabled: true,
        title: 'relation-overview',
        type: 'item',
        icon: <PeopleIcon sx={iconStyles} />,
        auth: authRoles.user,
        url: navigation.relationOverview(),
      },
      {
        id: 'creditpolicy-component',
        title: 'credit-policy',
        type: 'item',
        icon: <PolicyIcon sx={iconStyles} />,
        auth: authRoles.user,
        url: navigation.creditPolicy(),
        permissions: 'allow_credit_policy',
      },
      {
        id: 'fraud-indicators-component',
        title: 'fraud-module',
        type: 'item',
        icon: <ErrorIcon sx={iconStyles} />,
        auth: authRoles.user,
        permissions: 'fraud_indicators',
        url: navigation.fraudIndicators(),
      },
      {
        id: 'enterprise-credit-policy-component',
        title: 'enterprise-credit-policy',
        type: 'item',
        icon: <PolicyIcon sx={iconStyles} />,
        auth: authRoles.user,
        url: navigation.enterpriseCreditPolicy(),
        permissions: 'enterprise_credit_policy',
        subUrls: ['credit-policy-builder'],
      },
      // {
      //   id: 'usermanagement-component',
      //   title: 'user-management',
      //   type: 'item',
      //   // 'icon' : 'people_outline' ,
      //   icon: <FolderShared />,
      //   auth: authRoles.user,
      //   url: '/usermanagement',
      // },
      {
        id: 'customermanagement-component',
        title: 'customer-management',
        type: 'item',
        // 'icon' : 'people_outline' ,
        icon: <FontAwesomeIcon size="lg" fixedWidth icon={faUserTie as Icon} />,
        auth: authRoles.staff,
        url: navigation.customerManagement(),
        dataCy: 'side-menu-customer-management',
      },
      {
        id: 'advancedfilters-component',
        title: 'advanced-filters',
        type: 'item',
        icon: <FilterAltIcon sx={iconStyles} />,
        auth: authRoles.user,
        url: navigation.filtering(),
        hideMobile: true,
      },
    ],
  },
  {
    id: 'support',
    title: 'support',
    type: 'group',
    children: [
      {
        id: 'faq-component',
        title: 'faq',
        type: 'item',
        icon: <HelpIcon sx={iconStyles} />,
        auth: authRoles.user,
        url: FAQ_LINK,
        externalLink: true,
      },
      {
        id: 'chat-component',
        title: 'chat',
        type: 'item',
        icon: <ChatBubbleIcon sx={iconStyles} />,
        auth: authRoles.user,
        action: () => {
          // if (window?.HubSpotConversations?.widget) {
          //   window.HubSpotConversations.widget.open()
          // }
        },
      },
    ],
  },
]
