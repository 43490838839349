import { useState } from 'react'
import {
  ColumnsDefinition,
  SelectedType,
  TableDataAPIType,
} from 'components-new/data-display/Table/Table.types'
import { TableWithToolbar, ErrorPageSimple } from 'components-new'
import {
  monitoringColumnsDefinition,
  blankTableAPIData,
} from './ECPMonitoringTable.model'

import MonitoringTableToolbar from './widgets/MonitoringTableToolbar'
import lodash from 'lodash'
import { countActiveFilters } from '../../../observational-lists/ObservationalListsPage.controller'
import { riskIntelligenceApiKeys as apiKeys } from '../../../../configs/constants/api'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useCreditAutomatorTableContext } from './context'
import { formatData } from './ECPMonitoringTable.controller'
import { useAccountStatus, useUserSettings } from 'services/queries'
import { AvailableCurrencies } from 'globalTypes'

export type CreditAutomatorMonitoringTableProps = {
  data: TableDataAPIType | undefined
  entireData: TableDataAPIType | undefined
  listId: number
  listName: string
  selectedCurrency: AvailableCurrencies
  tableFilters: any
  setTableFilters: any
  isLoading: boolean
  isFiltersLoading: boolean
  isError: boolean
  refetchData: () => void
}
export const ECPMonitoringTable = ({
  data,
  entireData,
  listId,
  listName,
  selectedCurrency,
  tableFilters,
  setTableFilters,
  isLoading,
  isFiltersLoading,
  isError,
  refetchData,
}: CreditAutomatorMonitoringTableProps) => {
  const { actions } = useCreditAutomatorTableContext()
  const [selected, setSelected] = useState<SelectedType[]>([])
  const accountStatus = useAccountStatus()
  const { data: settingsData } = useUserSettings()
  const monitorTableFields = settingsData?.monitorTableFields ?? []
  const { rights } = accountStatus.data ?? {}
  const global = rights?.global
  const shouldRenderFraudIndicators =
    global?.fraud_indicators && monitorTableFields.includes('fraudIndicators')

  const columnsDefinition = shouldRenderFraudIndicators
    ? monitoringColumnsDefinition
    : monitoringColumnsDefinition.filter((item) => item.key !== 'fraud_indicators_count')

  const handleFilters = (category: string, key: string) => {
    const nextFilterState: any = lodash.cloneDeep(tableFilters)
    // checkedValue is opposite of field current value
    let checkedValue = !nextFilterState[category][key].checked
    // But if there is only one checked value, user is not allowed to uncheck it
    if (
      (category === apiKeys.creditAutomatorResult || category === apiKeys.creditScore) &&
      countActiveFilters(nextFilterState[category]) <= 1
    ) {
      checkedValue = true
    }
    nextFilterState[category][key].checked = checkedValue
    setTableFilters(nextFilterState)
    actions.updatePage(1)
  }

  const handleSearch = (searchTerm: string) => {
    if (!Object.keys(tableFilters).length) return
    const nextFilterState: any = lodash.cloneDeep(tableFilters)
    nextFilterState[apiKeys.freeSearch] = searchTerm
    setTableFilters(nextFilterState)
    actions.updatePage(1)
  }

  if (isError) {
    return (
      <ErrorPageSimple
        content={<FormattedMessage id={intl.riskMonitoringNew('error-loading-page')} />}
      />
    )
  }

  return (
    // Table with toolbar is unnecessary. You can just render it like the loading state above
    <TableWithToolbar
      tableData={data ?? blankTableAPIData}
      entireData={entireData ?? blankTableAPIData}
      formatData={formatData}
      columnsDefinition={columnsDefinition as ColumnsDefinition}
      getSelected={setSelected}
      expandable={true}
      isLoading={isLoading}
      enableReorder
      refetch={refetchData}
      listId={listId}
    >
      <MonitoringTableToolbar
        isLoading={isFiltersLoading}
        listName={listName}
        listId={listId}
        selectedCurrency={selectedCurrency}
        selectedRows={selected}
        refetch={() => refetchData()}
        handleFilters={handleFilters}
        handleSearch={handleSearch}
        tableFilters={tableFilters}
      />
    </TableWithToolbar>
  )
}
