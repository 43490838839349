import { AxiosError } from 'axios'
import { ChangeType } from 'components-new/data-display/Table/elements/Changes/Change.types'
import { ECPAvailableCountries } from 'globalTypes'
import { useQuery } from 'react-query'
import { mostRelevantList } from 'services/api'

export type CompanyChangesType = {
  changes: ChangeType[]
  date: string
  local_organization_id: { country: ECPAvailableCountries; id: string }
  name: string
  weight: string
}

const useCompanyChanges = (listIdArray: number[]) => {
  const companyChangesData = useQuery<CompanyChangesType[][], AxiosError<Error>>(
    ['companyChanges', listIdArray],
    async () => {
      const data = await Promise.allSettled(
        listIdArray.map((listId) => mostRelevantList(listId))
      )

      const fulfilledData = data.filter((data) => data.status === 'fulfilled')

      const returnData = fulfilledData.map((data) => {
        return data.value
      })

      return returnData
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
  return { ...companyChangesData }
}

export { useCompanyChanges }

// TODO, add functionality that allows the user to select which list they want to see
