export const subLabel = {
  fontWeight: 700,
}

export const link1 = {
  fontFamily: 'Lato',
  fontSize: '16px',
  fontWeight: 600,
  textDecoration: 'underline',
  color: 'primary.700',
  cursor: 'pointer',
  '& p': {
    color: 'primary.700',
  },
}
