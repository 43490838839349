import { useState, useEffect } from 'react'
import {
  Tooltip,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
} from '@mui/material'
import { ActionDialog } from 'components'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { colors } from 'configs'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { classes } from '../../../styles/table-settings'
import SettingsIcon from '@mui/icons-material/Settings'
import { standardIcon } from 'styles-new/global/components'
import { useUpdateUserSettingsField, useUserSettingsField } from 'services/queries'
import { useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'

export const defaultTableFields = [
  { key: 'companyName', visible: true },
  { key: 'localId', visible: true },
  { key: 'country', visible: true },
  { key: 'score', visible: true },
  { key: 'creditMax', visible: true },
  { key: 'profitLoss', visible: true },
  { key: 'equity', visible: true },
  { key: 'employeeInterval', visible: true },
]

const reorder = (list, startIndex, endIndex) => {
  const newList = list
  const [removed] = newList.splice(startIndex, 1)
  newList.splice(endIndex, 0, removed)

  return newList
}
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? colors?.risikaLight : 'white',
  marginRight: '2rem',
})
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  // change background colour if dragging
  background: isDragging ? colors?.risikaContrast : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const USER_SETTINGS_FIELD_MONITOR_TABLE = 'monitor_table'

// Split it in 2 rows active and inactive and when clicked a field goes to active or inactive
const TableSettings = () => {
  // const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const [monitorTableFields, setMonitorTableFields] = useState(defaultTableFields)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { messages } = useIntl()

  const { data: monitorTableColumns, isFetching } = useUserSettingsField(
    USER_SETTINGS_FIELD_MONITOR_TABLE
  )

  const onSuccess = () => {
    return enqueueSnackbar(messages[intl.snackbar('settings-saved')], {
      variant: 'success',
      onClick: () => closeSnackbar(),
      preventDuplicate: true,
    })
  }

  const onError = () => {
    return enqueueSnackbar(messages[intl.snackbar('report-error-something-wrong')], {
      variant: 'error',
      onClick: () => closeSnackbar(),
      preventDuplicate: true,
    })
  }

  const { mutate: updateUserSettingsField } = useUpdateUserSettingsField({
    onSuccess,
    onError,
  })

  useEffect(() => {
    if (isFetching || !monitorTableColumns) {
      setMonitorTableFields(defaultTableFields)
    } else {
      setMonitorTableFields(monitorTableColumns)
    }
  }, [monitorTableColumns, isFetching])

  const handleChange = (event) => {
    const { checked, name } = event.target
    setMonitorTableFields(
      monitorTableFields.map((field) => {
        if (field.key === name) {
          return { ...field, visible: checked }
        }
        return field
      })
    )
  }

  const handleUpdate = () => {
    updateUserSettingsField({ field: 'monitor_table', data: monitorTableFields })
  }

  const handleClose = () => {
    queryClient.invalidateQueries(
      'getUserSettingsField' + USER_SETTINGS_FIELD_MONITOR_TABLE
    )
  }

  const onDragEnd = (res) => {
    if (!res.destination) {
      return
    }
    const newFields = reorder(monitorTableFields, res.source.index, res.destination.index)
    setMonitorTableFields(newFields)
  }

  // Move to controller when refactor the component
  const convertTableField = (field) => {
    switch (field) {
      case 'localId':
        return 'local-id'
      case 'creditMax':
        return 'credit-max'
      case 'fraudIndicators':
        return 'fraud-indicators-column'
      default:
        return field
    }
  }

  return (
    <ActionDialog
      title={<FormattedMessage id={intl.riskMonitoringNew('settings-header')} />}
      content={(close) => (
        <Box sx={classes.container}>
          <Box sx={classes.columnWrapper}>
            <Typography variant="subtitle1" sx={classes.columnTitle}>
              <FormattedMessage id={intl.riskMonitoringNew('table-shown')} />
            </Typography>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <FormGroup
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    sx={getListStyle(snapshot.isDraggingOver)}
                  >
                    {monitorTableFields
                      .filter((x) => x.visible)
                      .map((x) => x.key)
                      .map((tableField, i) => (
                        <Draggable key={tableField} draggableId={tableField} index={i}>
                          {(provided, snapshot) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Box sx={classes.item}>
                                <Typography variant="h4" sx={classes.number}>
                                  {i + 1}
                                </Typography>
                                <FormControlLabel
                                  disabled={tableField === 'companyName'}
                                  control={
                                    <Checkbox
                                      checked={
                                        monitorTableFields?.filter(
                                          (x) => x.key === tableField
                                        )[0].visible
                                      }
                                      onChange={handleChange}
                                      name={tableField}
                                    />
                                  }
                                  label={
                                    <FormattedMessage
                                      id={intl.riskMonitoring(
                                        convertTableField(tableField)
                                      )}
                                    />
                                  }
                                />
                              </Box>
                              <FontAwesomeIcon icon={faBars} style={classes.dragIcon} />
                            </Box>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </FormGroup>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <Box sx={classes.columnWrapper}>
            <Typography variant="subtitle1" sx={classes.columnTitle}>
              <FormattedMessage id={intl.riskMonitoringNew('table-hidden')} />
            </Typography>
            <FormGroup>
              {monitorTableFields
                .filter((x) => !x.visible)
                .map((x) => x.key)
                .map((tableField) => (
                  <FormControlLabel
                    sx={classes.item}
                    key={tableField}
                    disabled={tableField === 'companyName'}
                    control={
                      <Checkbox
                        checked={
                          monitorTableFields?.filter(
                            (field) => field.key === tableField
                          )[0].visible
                        }
                        onChange={handleChange}
                        name={tableField}
                      />
                    }
                    label={
                      <FormattedMessage
                        id={intl.riskMonitoring(convertTableField(tableField))}
                      />
                    }
                  />
                ))}
            </FormGroup>
          </Box>
        </Box>
      )}
      action={() => handleUpdate()}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
      render={(open) => (
        <Tooltip
          disableFocusListener={true}
          title={
            <FormattedMessage id={intl.riskMonitoringNew('handle-settings-button')} />
          }
        >
          <Box sx={classes.buttonWrapper}>
            <IconButton color="primary" onClick={open}>
              <SettingsIcon sx={standardIcon} />
            </IconButton>
          </Box>
        </Tooltip>
      )}
      agreeText={<FormattedMessage id={intl.riskMonitoringNew('update')} />}
    />
  )
}

export default TableSettings
