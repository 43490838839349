import { useFraudIndicatorsPolicyContext } from '../../FraudIndicatorsProvider'
import { Box, Skeleton, Stack } from '@mui/material'
import classes from '../../styles'
import SwitchWithLabel from '../SwitchWithLabel'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { ButtonSecondary } from 'components-new'
import { useFraudIndicatorsPolicyUpdate } from 'services/queries'
import { useSnackbar } from 'notistack'

const BottomBar = () => {
  const {
    selectedCountry,
    isEnabled,
    setIsActivationModalOpen,
    indicators,
    isFraudIndicatorsPolicyDataFetching,
    hasFraudIndicatorsChanged,
  } = useFraudIndicatorsPolicyContext()
  const { enqueueSnackbar } = useSnackbar()
  const onSuccess = () => {
    enqueueSnackbar(
      <FormattedMessage id={intl.fraudIndicators('save-changes-success')} />,
      {
        variant: 'success',
      }
    )
  }
  const onError = () => {
    enqueueSnackbar(intl.fraudIndicators('save-changes-error'), {
      variant: 'error',
    })
  }
  const { updatePolicy, isLoading: isSaving } = useFraudIndicatorsPolicyUpdate({
    onSuccess,
    onError,
  })
  const handleSave = () => {
    const payload = {
      enabled: isEnabled,
      policy: indicators,
      country: selectedCountry,
    }
    updatePolicy(payload)
  }
  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={3}
        sx={classes.saveSection}
      >
        <Box sx={classes.parentContainer}>
          {isFraudIndicatorsPolicyDataFetching ? (
            <Skeleton variant="rounded" width={100} height={45} />
          ) : (
            <SwitchWithLabel
              labelChecked={<FormattedMessage id={intl.fraudIndicators('active')} />}
              labelUnChecked={<FormattedMessage id={intl.fraudIndicators('inactive')} />}
              checked={isEnabled}
              onChange={() => setIsActivationModalOpen(true)}
              disabled={isSaving}
            />
          )}
          {isFraudIndicatorsPolicyDataFetching ? (
            <Skeleton
              sx={{ marginLeft: '20px' }}
              variant="rounded"
              width={150}
              height={45}
            />
          ) : (
            <ButtonSecondary
              disabled={!hasFraudIndicatorsChanged || isSaving}
              data-cy="fraud-indicators-save"
              onClick={handleSave}
            >
              <FormattedMessage id={intl.fraudIndicators('save-changes')} />
            </ButtonSecondary>
          )}
        </Box>
      </Stack>
    </>
  )
}

export default BottomBar
