import { Typography, Button } from '@mui/material'
import { styled } from '@mui/system'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { colors } from 'configs'
import { FraudIndicatorsCTA } from 'pages/advanced-credit-policy/widgets'

const StyledMainContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  '@media screen and (min-width: 600px)': {
    width: '84rem',
    margin: '0 auto',
  },
  '@media screen and (max-width: 600px)': {
    padding: '2rem',
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: colors?.risikaContrast,
  textAlign: 'center',
  padding: '1.5rem 0',
  width: '32rem',
  '&:hover': {
    backgroundColor: colors?.risikaContrast,
  },
  '@media screen and (min-width: 600px)': {
    marginTop: '3.5rem',
    height: '6rem',

    borderRadius: '0.25rem',
    fontSize: '1.125rem',
  },
  '@media screen and (max-width: 600px)': {
    marginTop: '2rem',
    width: '30rem',
    height: '5.5rem',
  },
}))

const StyledLogo = styled('img')(({ theme }) => ({
  '@media screen and (min-width: 600px)': {
    width: '32rem',
  },
  '@media screen and (max-width: 600px)': {
    marginTop: '2rem',
    marginBottom: '3rem',
  },
}))

const StyledErrorMessage = styled(Typography)(({ theme }) => ({
  maxWidth: '36rem',
  fontSize: '1.5rem',
  textAlign: 'center',
}))

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1.5rem',
  marginTop: '1.5rem',
  '@media screen and (min-width: 600px)': {
    marginBottom: '2rem',
  },
  '@media screen and (max-width: 600px)': {
    display: 'contents',
  },
}))

const StyledCTA = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '32rem',
  margin: '1.5rem 0',
  padding: '1.5rem 0',
  '@media screen and (min-width: 600px)': {
    marginBottom: '2rem',
  },
  '@media screen and (max-width: 600px)': {
    display: 'contents',
  },
}))

const ButtonComponent = () => {
  return (
    <StyledButton href="/home">
      <FormattedMessage id={intl.notFound('not-found-button-dashboard')} />
    </StyledButton>
  )
}

const FraudIndicatorsSubscribe = () => {
  return (
    <StyledMainContainer className="bg-risika">
      <StyledContainer>
        <StyledLogo src={'/risika/Risika_logo_BlueShield.svg'} alt="Risika Logo" />
      </StyledContainer>
      <StyledErrorMessage>
        <FormattedMessage id={intl.fraudIndicators('no-access-to-fraud-module')} />
      </StyledErrorMessage>
      <StyledCTA>
        <FraudIndicatorsCTA />
        <ButtonComponent />
      </StyledCTA>
    </StyledMainContainer>
  )
}

export default FraudIndicatorsSubscribe
