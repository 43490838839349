import { useIntl } from 'react-intl'
import { useFraudIndicatorsPolicyContext } from '../../FraudIndicatorsProvider'
import FraudIndicatorsActivator from '../FraudIndicatorsActivator'
import intl from 'localization/components'
import SectionContainer from './SectionContainer'
import { IndicatorKeys } from '../../types'
import {
  CountrySection,
  dkSectionsSchema,
  fiSectionsSchema,
  FraudSection,
  noSectionsSchema,
  Section,
  sectionTranslationKeys,
  seSectionsSchema,
} from './FraudIndicatorCountrySchemas'

const FraudIndicatorsContent = () => {
  const {
    selectedCountry,
    indicators,
    setIndicators,
    isFraudIndicatorsPolicyDataFetching,
  } = useFraudIndicatorsPolicyContext()
  const reactIntl = useIntl()
  const { formatMessage } = reactIntl

  const handleSwitchChange = (key: string, value: boolean) => {
    setIndicators({
      ...indicators,
      [key]: value,
    })
  }

  const generateActivator = ({
    title,
    description,
    key,
  }: {
    title: string
    description: string
    key: IndicatorKeys
  }) => {
    return (
      <FraudIndicatorsActivator
        title={title}
        description={description}
        selected={indicators[key]}
        setSelected={(value: boolean) => {
          handleSwitchChange(key, value)
        }}
        key={key}
        dataCy={key}
      />
    )
  }

  const buildSection = (data: CountrySection, key: FraudSection) => {
    const section = (data as { [k in typeof key]: Record<IndicatorKeys, boolean> })[key] // typescript gymnastics to say it can be one of the titles
    const children: JSX.Element[] = []
    const keys = Object.keys(section) as (keyof Section)[] as IndicatorKeys[]

    keys.forEach((subKey) => {
      if (section[subKey]) {
        children.push(
          generateActivator({
            title: formatMessage({
              id: intl.fraudIndicators(sectionTranslationKeys[subKey].title),
            }),
            description: formatMessage({
              id: intl.fraudIndicators(sectionTranslationKeys[subKey].description),
            }),
            key: subKey,
          })
        )
      }
    })

    return {
      title: formatMessage({
        id: intl.fraudIndicators(key),
      }),
      children,
    }
  }

  const buildSections = (schema: CountrySection[]) => {
    const sectionKeys = ['address', 'financial', 'company'] as FraudSection[]
    return schema.map((section, i) => {
      return buildSection(section, sectionKeys[i])
    })
  }

  switch (selectedCountry) {
    case 'dk':
      return (
        <SectionContainer
          isLoading={isFraudIndicatorsPolicyDataFetching}
          dataCy={'fraud-indicators-danish'}
          sections={buildSections(dkSectionsSchema)}
        />
      )
    case 'se':
      return (
        <SectionContainer
          isLoading={isFraudIndicatorsPolicyDataFetching}
          dataCy={'fraud-indicators-swedish'}
          sections={buildSections(seSectionsSchema)}
        />
      )
    case 'no':
      return (
        <SectionContainer
          isLoading={isFraudIndicatorsPolicyDataFetching}
          dataCy={'fraud-indicators-norwegian'}
          sections={buildSections(noSectionsSchema)}
        />
      )
    case 'fi':
      return (
        <SectionContainer
          isLoading={isFraudIndicatorsPolicyDataFetching}
          dataCy={'fraud-indicators-finnish'}
          sections={buildSections(fiSectionsSchema)}
        />
      )
  }
}

export default FraudIndicatorsContent
