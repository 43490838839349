import { Show } from 'components'
import { Typography, Collapse, Box } from '@mui/material'
import { styled } from '@mui/system'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { isComponentRendered } from 'configs'
import { useCreditCheck } from 'pages/credit-check/context'
import SimpleCreditPolicy from './SimpleCreditPolicy'
import { ConflictT } from './CreditPolicy.types'
import { EnterpriseEvaluation } from 'services/api/endpoints/risikaAPI/evaluationPolicy'
import EcpFactory from './EcpFactory'
import { AxiosError } from 'axios'
import { useIsCompanyActive } from 'services/hooks'
import { LocalId } from 'globalTypes'
import { LoadingSkeleton } from 'components-new'
import CreditPolicySkeleton from './CreditPolicy.skeleton'
import { WidgetWrapper } from '../WidgetWrapper/WidgetWrapper'

type CreditPolicyProps = {
  widgetNameDND: string
  isReArrange: boolean
  isDragging: boolean
  companyStatus: string
  enterpriseEvaluation: EnterpriseEvaluation
  creditPolicy?: {
    allowed: boolean
    creditConflicts: ConflictT[]
    conflicts: ConflictT[]
  }
  enterpriseEvaluationError: AxiosError
  isLoading?: boolean
  isActive?: boolean
  setIsActive?: (key: string) => void
}

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
})) as typeof Box

const CreditPolicy = ({
  widgetNameDND,
  isReArrange = false,
  creditPolicy,
  enterpriseEvaluation,
  enterpriseEvaluationError,
  isLoading,
  isActive,
  setIsActive,
}: CreditPolicyProps) => {
  const isCompanyActive = useIsCompanyActive()
  const { localId } = useCreditCheck() as { localId: LocalId }

  return (
    <LoadingSkeleton SkeletonComponent={CreditPolicySkeleton} isLoading={isLoading}>
      <Show
        when={
          !!(isCompanyActive && isComponentRendered('creditPolicy', localId?.country))
        }
      >
        <WidgetWrapper
          isReArrange={isReArrange}
          widgetName={widgetNameDND}
          isActive={!!isActive}
          setIsActive={setIsActive}
          dataCy="creditPolicy"
        >
          <StyledBox>
            <Typography variant="h4">
              <FormattedMessage id={intl.mainRecommendation('credit-policy')} />
            </Typography>
            <Collapse in={!isReArrange}>
              <EcpFactory
                enterpriseEvaluation={enterpriseEvaluation}
                enterpriseEvaluationError={enterpriseEvaluationError}
              />
              {creditPolicy && <SimpleCreditPolicy conflicts={creditPolicy?.conflicts} />}
            </Collapse>
          </StyledBox>
        </WidgetWrapper>
      </Show>
    </LoadingSkeleton>
  )
}

export default CreditPolicy
