export type CountrySection =
  | { address: Record<string, boolean> }
  | { financial: Record<string, boolean> }
  | { company: Record<string, boolean> }

export type FraudSection = 'address' | 'financial' | 'company'

export type Section = {
  [key: string]: boolean
}

export const dkSectionsSchema: CountrySection[] = [
  {
    address: {
      companies_from_same_address: true,
      frequency_of_address_changes: true,
      distance_to_ceo: true,
    },
  },
  {
    financial: {
      profit_vs_employees_abnormalities: true,
      duplicate_statements: true,
      duplicate_statements_across_companies: true,
    },
  },
  {
    company: {
      ownership_changes: true,
      purchase_of_new_company: false,
      reverse_auditor_bankruptcy_changes: false,
      prepaid_phone_number: true,
      phone_number_registry: false,
      company_name_changes: true,
    },
  },
]

export const seSectionsSchema: CountrySection[] = [
  {
    address: {
      companies_from_same_address: true,
      frequency_of_address_changes: true,
      distance_to_ceo: true,
    },
  },
  {
    financial: {
      profit_vs_employees_abnormalities: true,
      duplicate_statements: true,
      duplicate_statements_across_companies: true,
    },
  },
  {
    company: {
      ownership_changes: true,
      purchase_of_new_company: false,
      reverse_auditor_bankruptcy_changes: false,
      prepaid_phone_number: false,
      phone_number_registry: false,
      company_name_changes: true,
    },
  },
]

export const fiSectionsSchema: CountrySection[] = [
  {
    address: {
      companies_from_same_address: true,
      frequency_of_address_changes: true,
      distance_to_ceo: true,
    },
  },
  {
    financial: {
      profit_vs_employees_abnormalities: false,
      duplicate_statements: true,
      duplicate_statements_across_companies: true,
    },
  },
  {
    company: {
      ownership_changes: true,
      purchase_of_new_company: false,
      reverse_auditor_bankruptcy_changes: false,
      prepaid_phone_number: false,
      phone_number_registry: false,
      company_name_changes: true,
    },
  },
]

export const noSectionsSchema: CountrySection[] = [
  {
    address: {
      companies_from_same_address: true,
      frequency_of_address_changes: true,
      distance_to_ceo: false,
    },
  },
  {
    financial: {
      profit_vs_employees_abnormalities: true,
      duplicate_statements: true,
      duplicate_statements_across_companies: true,
    },
  },
  {
    company: {
      ownership_changes: false,
      purchase_of_new_company: false,
      reverse_auditor_bankruptcy_changes: false,
      prepaid_phone_number: false,
      phone_number_registry: false,
      company_name_changes: true,
    },
  },
]

// any new data points should have their translation keys added here
export const sectionTranslationKeys = {
  companies_from_same_address: {
    title: 'same-address-title',
    description: 'same-address-description',
  },
  frequency_of_address_changes: {
    title: 'address-frequency-title',
    description: 'address-frequency-description',
  },
  profit_vs_employees_abnormalities: {
    title: 'profit-employees-title',
    description: 'profit-employees-description',
  },
  duplicate_statements: {
    title: 'duplicate-statements-title',
    description: 'duplicate-statements-description',
  },
  reverse_auditor_bankruptcy_changes: {
    title: 'auditor-bankruptcies-title',
    description: 'auditor-bankruptcies-description',
  },
  financial_statement_abnormalities_year_over: {
    title: 'financial-statements-title',
    description: 'financial-statements-description',
  },
  duplicate_statements_across_companies: {
    title: 'duplicate-statements-multiple-companies-title',
    description: 'duplicate-statements-multiple-companies-description',
  },
  ownership_changes: {
    title: 'ownership-changes-title',
    description: 'ownership-changes-description',
  },
  purchase_of_new_company: {
    title: 'purchase-of-new-company-title',
    description: 'purchase-of-new-company-description',
  },
  prepaid_phone_number: {
    title: 'prepaid-phone-number-title',
    description: 'prepaid-phone-number-description',
  },
  phone_number_registry: {
    title: 'phone-number-registry-title',
    description: 'phone-number-registry-description',
  },
  distance_to_ceo: {
    title: 'distance-to-ceo-title',
    description: 'distance-to-ceo-description',
  },
  company_name_changes: {
    title: 'company-name-changes-title',
    description: 'company-name-changes-description',
  },
}

export const sectionKeys = ['address', 'financial', 'company'] as FraudSection[]
