import { theme } from 'styles-new/mui5-transition/theme'

export const classGlobalSearch = {
  position: 'fixed' as 'fixed',
  top: '6rem',
  zIndex: 2,
  width: 'calc(100vw - 100px)',
  [theme.breakpoints.up('sm')]: {
    width: 'calc(100vw - 100px)',
  },
  height: '100%',
  overflowY: 'scroll' as 'scroll',
  overscrollBehavior: 'contain',
  backgroundColor: theme.palette.grey[100],
}
