export type LocalId = {
  id: string
  country: string
} | null

type InternalIdEntry = {
  internalId: string
  localId: LocalId
}

export const formatInternalIds = (internalIds: InternalIdEntry[]): InternalIdEntry[] => {
  return internalIds.map((entry) => {
    if (
      entry.localId &&
      entry.localId.country.toLowerCase() === 'fi' &&
      typeof entry.localId.id === 'string' &&
      /^\d+$/.test(entry.localId.id)
    ) {
      const id = entry.localId.id
      entry.localId.id = id.slice(0, -1) + '-' + id.slice(-1)
    }
    return entry
  })
}

export const createNotesOfInternalIds = (formattedInternalIds: InternalIdEntry[]) =>
  formattedInternalIds
    .filter(({ internalId }) => internalId != null)
    .map(({ localId, internalId }) => ({
      data: {
        _internal_id: internalId === '' ? null : internalId,
      },
      localId,
    }))
