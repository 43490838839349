import { TableDataAPIType } from 'components-new/data-display/Table/Table.types'
import intl from 'localization/components'
import { getFormattedDateForScheduler, getTodayDifferentYear } from 'localization/utils'

export const apiParameters = {
  page: 1,
  limit: 20,
  dates: {
    from: getFormattedDateForScheduler(getTodayDifferentYear(-1)),
    to: getFormattedDateForScheduler(new Date()),
  },
  sort_by: {},
  filter_on: {},
}

export const blankTableAPIData: TableDataAPIType = {
  credit_policy_used: 'credit_policy',
  data: [],
  count: 0,
  page_count: 0,
}

export const monitoringColumnsDefinition = [
  {
    key: 'id',
    sub_key: 'local_organization_id',
    type: 'number',
    justifyContent: '',
    width: 0,
  },
  {
    key: 'checkbox',
    sub_key: null,
    width: 1,
    minWidth: '50px',
    justifyContent: 'center',
    type: 'checkbox',
  },
  // Company Name
  {
    key: 'company_name',
    sub_key: null,
    label: intl.generic('company-name'),
    width: 3,
    minWidth: '200px',
    justifyContent: 'flex-start',
    type: 'text',
  },
  // Local ID
  {
    key: 'local_organization_id',
    sub_key: 'id',
    label: intl.generic('local-id'),
    width: 1,
    minWidth: '120px',
    justifyContent: 'center',
    type: 'text',
  },
  // Fraud Indicators
  {
    key: 'fraud_indicators_count',
    sub_key: null,
    label: intl.companyInfo('fraud-indicators-title'),
    width: 1,
    minWidth: '100px',
    justifyContent: 'center',
    type: 'text',
  },
  // Policy outcome
  {
    key: 'credit_automator_result',
    sub_key: null,
    label: intl.generic('policy-outcome'),
    width: 1,
    minWidth: '150px',
    justifyContent: 'center',
    type: 'text',
  },
  // Policy Credit limit
  {
    key: 'value',
    sub_key: 'credit_policy_credit_max',
    label: intl.generic('policy-credit-limit'),
    width: 1,
    minWidth: '200px',
    justifyContent: 'flex-end',
    type: 'number_currency',
  },
  // Risika recommendation
  {
    key: 'credit_max',
    sub_key: null,
    label: intl.generic('risika-recommendation'),
    width: 4,
    minWidth: '200px',
    justifyContent: 'flex-end',
    type: 'number_currency',
  },
  // Score
  {
    key: 'score',
    sub_key: null,
    label: intl.riskMonitoring('score'),
    width: 1,
    minWidth: '100px',
    justifyContent: 'center',
    type: 'score',
  },
  // Country
  {
    key: 'country',
    sub_key: 'local_organization_id',
    label: intl.generic('country'),
    width: 1,
    minWidth: '120px',
    justifyContent: 'center',
    type: 'text',
  },
  // Status
  {
    key: 'status_code',
    sub_key: null,
    label: intl.generic('status'),
    width: 2,
    minWidth: '200px',
    justifyContent: 'center',
    type: 'text_status',
  },
  // Profit and loss
  {
    key: 'profit_loss',
    sub_key: 'financial_key_figures',
    label: intl.generic('profit-loss'),
    width: 3,
    minWidth: '200px',
    justifyContent: 'flex-end',
    type: 'number_currency',
  },
  // Equity
  {
    key: 'equity',
    sub_key: 'financial_key_figures',
    label: intl.generic('equity'),
    width: 3,
    minWidth: '200px',
    justifyContent: 'flex-end',
    type: 'number_currency',
  },
  // Employees interval
  {
    key: 'employee_interval',
    sub_key: null,
    label: intl.riskMonitoring('employee-interval'),
    width: 2,
    minWidth: '150px',
    justifyContent: 'flex-end',
    type: 'text',
  },
  // Currency
  {
    key: 'currency',
    sub_key: 'financial_key_figures',
    label: intl.keyFinancialFigures('currency'),
    width: 2,
    minWidth: '150px',
    justifyContent: 'flex-end',
    type: 'text',
  },
  // Company type
  {
    key: 'company_type',
    sub_key: null,
    label: intl.generic('company-type'),
    width: 2,
    minWidth: '150px',
    justifyContent: 'flex-end',
    type: 'text',
  },
  // Solidity
  {
    key: 'solidity',
    sub_key: 'financial_key_figures',
    label: intl.generic('solidity'),
    width: 2,
    minWidth: '150px',
    justifyContent: 'flex-end',
    type: 'text',
  },
  // Date of incorporation
  {
    key: 'date_of_incorporation',
    sub_key: null,
    label: intl.generic('date-of-incorporation'),
    width: 2,
    minWidth: '150px',
    justifyContent: 'flex-end',
    type: 'text',
  },
  // Internal ID
  {
    key: 'internal_id',
    sub_key: null,
    label: intl.generic('internal-id'),
    width: 2,
    minWidth: '150px',
    justifyContent: 'flex-end',
    type: 'text',
  },
  // Capital
  {
    key: 'capital',
    sub_key: null,
    label: intl.keyFinancialFigures('capital'),
    width: 2,
    minWidth: '150px',
    justifyContent: 'flex-end',
    type: 'number_currency',
  },
  // Liquidity
  {
    key: 'liquidity',
    sub_key: 'financial_key_figures',
    label: intl.generic('liquidity'),
    width: 2,
    minWidth: '150px',
    justifyContent: 'flex-end',
    type: 'text',
  },
] as const
