import { authRoles } from 'components/auth'
import FraudIndicators from './FraudIndicators'
import { navigation } from 'services/navigation'

export const FraudIndicatorsPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: navigation.fraudIndicators(),
      component: FraudIndicators,
    },
  ],
}
