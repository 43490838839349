import Highlight from './Highlight'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

interface ErrorResponse {
  response?: {
    status?: number
  }
}

export const FraudIndicators = ({
  error,
  highlightsToShow,
  dataCy,
}: {
  error?: ErrorResponse
  highlightsToShow?: any[]
  dataCy: string
}) => {
  if (error?.response?.status === 400) {
    return (
      <div data-cy="credit-check-fraud-indicators-no-policy-set">
        <Highlight
          blurred={false}
          title={
            <FormattedMessage
              id={intl.mainRecommendation('fraud-indicators-policy-not-set-title')}
            />
          }
          message={
            <FormattedMessage
              id={intl.mainRecommendation('fraud-indicators-policy-not-set-description')}
            />
          }
          classification="default"
        />
      </div>
    )
  }
  if (!highlightsToShow?.length) {
    return (
      <div data-cy="credit-check-fraud-indicators-none-identified">
        <Highlight
          blurred={false}
          title={
            <FormattedMessage
              id={intl.mainRecommendation('fraud-indicators-none-title')}
            />
          }
          message={
            <FormattedMessage
              id={intl.mainRecommendation('fraud-indicators-none-description')}
            />
          }
          classification="positiveCheck"
        />
      </div>
    )
  }
  return highlightsToShow.map(
    ({ title, message, classification, key: highlight, blurred }) => {
      return (
        <div key={highlight} data-cy={dataCy}>
          <Highlight
            blurred={blurred}
            title={title}
            message={message}
            classification={classification}
          />
        </div>
      )
    }
  )
}
